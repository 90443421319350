import { Inject, Injectable, Injector } from '@angular/core';

import { BarcodeParsing_ReportService } from './BarcodeParsing.report.index';
import { BarTender_ReportService } from './BarTender.report.index';
import { Utilities_ReportService } from './Utilities.report.index';
import { Addresses_ReportService } from './Addresses.report.index';
import { Attachments_ReportService } from './Attachments.report.index';
import { Carriers_ReportService } from './Carriers.report.index';
import { Cartonization_ReportService } from './Cartonization.report.index';
import { EntityImport_ReportService } from './EntityImport.report.index';
import { Equipment_ReportService } from './Equipment.report.index';
import { ExcelMaterialImport_ReportService } from './ExcelMaterialImport.report.index';
import { ExcelProjectImport_ReportService } from './ExcelProjectImport.report.index';
import { Inspections_ReportService } from './Inspections.report.index';
import { Instructions_ReportService } from './Instructions.report.index';
import { InventoryTransfers_ReportService } from './InventoryTransfers.report.index';
import { LaborManagement_ReportService } from './LaborManagement.report.index';
import { LoadContainers_ReportService } from './LoadContainers.report.index';
import { Locations_ReportService } from './Locations.report.index';
import { PrintNode_ReportService } from './PrintNode.report.index';
import { Materials_ReportService } from './Materials.report.index';
import { PackVerification_ReportService } from './PackVerification.report.index';
import { PalletTransactions_ReportService } from './PalletTransactions.report.index';
import { PurchaseOrders_ReportService } from './PurchaseOrders.report.index';
import { Replenishments_ReportService } from './Replenishments.report.index';
import { Returns_ReportService } from './Returns.report.index';
import { ShippingContainers_ReportService } from './ShippingContainers.report.index';
import { Surveys_ReportService } from './Surveys.report.index';
import { TemperatureReadings_ReportService } from './TemperatureReadings.report.index';
import { TransloadOrders_ReportService } from './TransloadOrders.report.index';
import { Waves_ReportService } from './Waves.report.index';
import { WorkOrders_ReportService } from './WorkOrders.report.index';
import { Lots_ReportService } from './Lots.report.index';
import { Invoices_ReportService } from './Invoices.report.index';
import { DockAppointments_ReportService } from './DockAppointments.report.index';
import { Inventory_ReportService } from './Inventory.report.index';
import { ExcelLocationImport_ReportService } from './ExcelLocationImport.report.index';
import { SerialNumbers_ReportService } from './SerialNumbers.report.index';
import { InventoryCounts_ReportService } from './InventoryCounts.report.index';
import { AsnOrders_ReportService } from './AsnOrders.report.index';
import { Manifesting_ReportService } from './Manifesting.report.index';
import { Owners_ReportService } from './Owners.report.index';
import { Discussions_ReportService } from './Discussions.report.index';
import { Notifications_ReportService } from './Notifications.report.index';
import { WarehouseTransfers_ReportService } from './WarehouseTransfers.report.index';
import { ExcelInventoryImport_ReportService } from './ExcelInventoryImport.report.index';
import { SalesOrders_ReportService } from './SalesOrders.report.index';
import { ExcelOrderImport_ReportService } from './ExcelOrderImport.report.index';
import { Document360_ReportService } from './Document360.report.index';
import { Usersnap_ReportService } from './Usersnap.report.index';


@Injectable({ providedIn: 'root' })
export class FootPrintManager_ReportService {

  constructor(
    private injector: Injector
  ) {
    this.BarcodeParsing = this.injector.get(BarcodeParsing_ReportService);
    this.BarTender = this.injector.get(BarTender_ReportService);
    this.Utilities = this.injector.get(Utilities_ReportService);
    this.Addresses = this.injector.get(Addresses_ReportService);
    this.Attachments = this.injector.get(Attachments_ReportService);
    this.Carriers = this.injector.get(Carriers_ReportService);
    this.Cartonization = this.injector.get(Cartonization_ReportService);
    this.EntityImport = this.injector.get(EntityImport_ReportService);
    this.Equipment = this.injector.get(Equipment_ReportService);
    this.ExcelMaterialImport = this.injector.get(ExcelMaterialImport_ReportService);
    this.ExcelProjectImport = this.injector.get(ExcelProjectImport_ReportService);
    this.Inspections = this.injector.get(Inspections_ReportService);
    this.Instructions = this.injector.get(Instructions_ReportService);
    this.InventoryTransfers = this.injector.get(InventoryTransfers_ReportService);
    this.LaborManagement = this.injector.get(LaborManagement_ReportService);
    this.LoadContainers = this.injector.get(LoadContainers_ReportService);
    this.Locations = this.injector.get(Locations_ReportService);
    this.PrintNode = this.injector.get(PrintNode_ReportService);
    this.Materials = this.injector.get(Materials_ReportService);
    this.PackVerification = this.injector.get(PackVerification_ReportService);
    this.PalletTransactions = this.injector.get(PalletTransactions_ReportService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_ReportService);
    this.Replenishments = this.injector.get(Replenishments_ReportService);
    this.Returns = this.injector.get(Returns_ReportService);
    this.ShippingContainers = this.injector.get(ShippingContainers_ReportService);
    this.Surveys = this.injector.get(Surveys_ReportService);
    this.TemperatureReadings = this.injector.get(TemperatureReadings_ReportService);
    this.TransloadOrders = this.injector.get(TransloadOrders_ReportService);
    this.Waves = this.injector.get(Waves_ReportService);
    this.WorkOrders = this.injector.get(WorkOrders_ReportService);
    this.Lots = this.injector.get(Lots_ReportService);
    this.Invoices = this.injector.get(Invoices_ReportService);
    this.DockAppointments = this.injector.get(DockAppointments_ReportService);
    this.Inventory = this.injector.get(Inventory_ReportService);
    this.ExcelLocationImport = this.injector.get(ExcelLocationImport_ReportService);
    this.SerialNumbers = this.injector.get(SerialNumbers_ReportService);
    this.InventoryCounts = this.injector.get(InventoryCounts_ReportService);
    this.AsnOrders = this.injector.get(AsnOrders_ReportService);
    this.Manifesting = this.injector.get(Manifesting_ReportService);
    this.Owners = this.injector.get(Owners_ReportService);
    this.Discussions = this.injector.get(Discussions_ReportService);
    this.Notifications = this.injector.get(Notifications_ReportService);
    this.WarehouseTransfers = this.injector.get(WarehouseTransfers_ReportService);
    this.ExcelInventoryImport = this.injector.get(ExcelInventoryImport_ReportService);
    this.SalesOrders = this.injector.get(SalesOrders_ReportService);
    this.ExcelOrderImport = this.injector.get(ExcelOrderImport_ReportService);
    this.Document360 = this.injector.get(Document360_ReportService);
    this.Usersnap = this.injector.get(Usersnap_ReportService);
  }
    public BarcodeParsing: BarcodeParsing_ReportService;
    public BarTender: BarTender_ReportService;
    public Utilities: Utilities_ReportService;
    public Addresses: Addresses_ReportService;
    public Attachments: Attachments_ReportService;
    public Carriers: Carriers_ReportService;
    public Cartonization: Cartonization_ReportService;
    public EntityImport: EntityImport_ReportService;
    public Equipment: Equipment_ReportService;
    public ExcelMaterialImport: ExcelMaterialImport_ReportService;
    public ExcelProjectImport: ExcelProjectImport_ReportService;
    public Inspections: Inspections_ReportService;
    public Instructions: Instructions_ReportService;
    public InventoryTransfers: InventoryTransfers_ReportService;
    public LaborManagement: LaborManagement_ReportService;
    public LoadContainers: LoadContainers_ReportService;
    public Locations: Locations_ReportService;
    public PrintNode: PrintNode_ReportService;
    public Materials: Materials_ReportService;
    public PackVerification: PackVerification_ReportService;
    public PalletTransactions: PalletTransactions_ReportService;
    public PurchaseOrders: PurchaseOrders_ReportService;
    public Replenishments: Replenishments_ReportService;
    public Returns: Returns_ReportService;
    public ShippingContainers: ShippingContainers_ReportService;
    public Surveys: Surveys_ReportService;
    public TemperatureReadings: TemperatureReadings_ReportService;
    public TransloadOrders: TransloadOrders_ReportService;
    public Waves: Waves_ReportService;
    public WorkOrders: WorkOrders_ReportService;
    public Lots: Lots_ReportService;
    public Invoices: Invoices_ReportService;
    public DockAppointments: DockAppointments_ReportService;
    public Inventory: Inventory_ReportService;
    public ExcelLocationImport: ExcelLocationImport_ReportService;
    public SerialNumbers: SerialNumbers_ReportService;
    public InventoryCounts: InventoryCounts_ReportService;
    public AsnOrders: AsnOrders_ReportService;
    public Manifesting: Manifesting_ReportService;
    public Owners: Owners_ReportService;
    public Discussions: Discussions_ReportService;
    public Notifications: Notifications_ReportService;
    public WarehouseTransfers: WarehouseTransfers_ReportService;
    public ExcelInventoryImport: ExcelInventoryImport_ReportService;
    public SalesOrders: SalesOrders_ReportService;
    public ExcelOrderImport: ExcelOrderImport_ReportService;
    public Document360: Document360_ReportService;
    public Usersnap: Usersnap_ReportService;

  public FootPrintManager: FootPrintManager_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
}

