import { Injectable } from '@angular/core';
import { MissingTranslationHandler, TranslateCompiler, TranslateParser, TranslateService, TranslateStore } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Language, LocalizationService, LocalizationSettings } from './localization.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Utilities_LocalizationService } from './Utilities.localization.service';
import { Addresses_LocalizationService } from './Addresses.localization.service';
import { Carriers_LocalizationService } from './Carriers.localization.service';
import { Instructions_LocalizationService } from './Instructions.localization.service';
import { Locations_LocalizationService } from './Locations.localization.service';
import { Materials_LocalizationService } from './Materials.localization.service';
import { PurchaseOrders_LocalizationService } from './PurchaseOrders.localization.service';
import { Invoices_LocalizationService } from './Invoices.localization.service';
import { DockAppointments_LocalizationService } from './DockAppointments.localization.service';
import { Owners_LocalizationService } from './Owners.localization.service';
import { Discussions_LocalizationService } from './Discussions.localization.service';
import { Notifications_LocalizationService } from './Notifications.localization.service';
import { SalesOrders_LocalizationService } from './SalesOrders.localization.service';
import { FootPrintApiManager_LocalizationService } from './FootPrintApiManager.localization.service';
import { Document360_LocalizationService } from './Document360.localization.service';
import { Usersnap_LocalizationService } from './Usersnap.localization.service';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';

@Injectable({
  providedIn: 'root'
})
export class app_LocalizationService
  extends LocalizationService<{  }> {

  public app: app_LocalizationService = this;

  public override get settings(): LocalizationSettings {
    return {"default":"","languages":[]}
  }
  
  private _referencedServices: LocalizationService<any>[] = [];

  constructor(
    httpClient: HttpClient,
    compiler: TranslateCompiler,
    parser: TranslateParser,
    missingHandler: MissingTranslationHandler,
    public Utilities: Utilities_LocalizationService,
    public Addresses: Addresses_LocalizationService,
    public Carriers: Carriers_LocalizationService,
    public Instructions: Instructions_LocalizationService,
    public Locations: Locations_LocalizationService,
    public Materials: Materials_LocalizationService,
    public PurchaseOrders: PurchaseOrders_LocalizationService,
    public Invoices: Invoices_LocalizationService,
    public DockAppointments: DockAppointments_LocalizationService,
    public Owners: Owners_LocalizationService,
    public Discussions: Discussions_LocalizationService,
    public Notifications: Notifications_LocalizationService,
    public SalesOrders: SalesOrders_LocalizationService,
    public FootPrintApiManager: FootPrintApiManager_LocalizationService,
    public Document360: Document360_LocalizationService,
    public Usersnap: Usersnap_LocalizationService,
    public FootPrintManager: FootPrintManager_LocalizationService
    ) {
    super(
      new TranslateService(
        new TranslateStore(),
        new TranslateHttpLoader(httpClient, '../assets/i18n/app/', '.json'),
        compiler,
        parser,
        missingHandler,
        true,
        true,
        false,
        ''));

    this._referencedServices.push(this.Utilities);

    this._referencedServices.push(this.Addresses);

    this._referencedServices.push(this.Carriers);

    this._referencedServices.push(this.Instructions);

    this._referencedServices.push(this.Locations);

    this._referencedServices.push(this.Materials);

    this._referencedServices.push(this.PurchaseOrders);

    this._referencedServices.push(this.Invoices);

    this._referencedServices.push(this.DockAppointments);

    this._referencedServices.push(this.Owners);

    this._referencedServices.push(this.Discussions);

    this._referencedServices.push(this.Notifications);

    this._referencedServices.push(this.SalesOrders);

    this._referencedServices.push(this.FootPrintApiManager);

    this._referencedServices.push(this.Document360);

    this._referencedServices.push(this.Usersnap);

    this._referencedServices.push(this.FootPrintManager);
    
    this.initialize();

  }

  public override setLanguage(lang: Language): void{
    super.setLanguage(lang);
  
    for (let i of this._referencedServices){
      i.setLanguage(lang);
    }
  }
}