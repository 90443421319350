import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { Invoices_ds_auto_invoicing_rule_group_by_optionsService } from './Invoices.datasource.index';
import { Invoices_ds_auto_invoicing_rules_editorService } from './Invoices.datasource.index';
import { Invoices_ds_billing_aggregation_strategies_ddService } from './Invoices.datasource.index';
import { Invoices_ds_billing_contract_accessorial_charges_template_editorService } from './Invoices.datasource.index';
import { Invoices_ds_billing_contract_accessorial_charges_template_lines_gridService } from './Invoices.datasource.index';
import { Invoices_ds_billing_contract_accessorial_charges_templates_gridService } from './Invoices.datasource.index';
import { Invoices_ds_billing_contract_auto_renew_types_ddService } from './Invoices.datasource.index';
import { Invoices_ds_billing_contract_line_details_gridService } from './Invoices.datasource.index';
import { Invoices_ds_billing_contract_line_details_validationService } from './Invoices.datasource.index';
import { Invoices_ds_billing_contract_lines_count_widgetService } from './Invoices.datasource.index';
import { Invoices_ds_billing_contract_lines_editorService } from './Invoices.datasource.index';
import { Invoices_ds_billing_contract_lines_gridService } from './Invoices.datasource.index';
import { Invoices_ds_billing_contract_lines_max_line_numberService } from './Invoices.datasource.index';
import { Invoices_ds_billing_contract_lines_validationService } from './Invoices.datasource.index';
import { Invoices_ds_billing_contract_total_widgetService } from './Invoices.datasource.index';
import { Invoices_ds_billing_contracts_editorService } from './Invoices.datasource.index';
import { Invoices_ds_billing_contracts_gridService } from './Invoices.datasource.index';
import { Invoices_ds_billing_contracts_statuses_ddService } from './Invoices.datasource.index';
import { Invoices_ds_billing_methods_ddService } from './Invoices.datasource.index';
import { Invoices_ds_billing_outbound_shipment_service_type_methods_ddService } from './Invoices.datasource.index';
import { Invoices_ds_billing_projects_gridService } from './Invoices.datasource.index';
import { Invoices_ds_billing_rates_gridService } from './Invoices.datasource.index';
import { Invoices_ds_billing_record_activity_listService } from './Invoices.datasource.index';
import { Invoices_ds_billing_record_editorService } from './Invoices.datasource.index';
import { Invoices_ds_billing_records_by_invoiceline_gridService } from './Invoices.datasource.index';
import { Invoices_ds_billing_records_gridService } from './Invoices.datasource.index';
import { Invoices_ds_billing_records_total_billed_by_current_month_widgetService } from './Invoices.datasource.index';
import { Invoices_ds_billing_records_total_billed_by_date_widgetService } from './Invoices.datasource.index';
import { Invoices_ds_billing_records_total_invoiced_by_date_widgetService } from './Invoices.datasource.index';
import { Invoices_ds_billing_supplemental_fees_gridService } from './Invoices.datasource.index';
import { Invoices_ds_billingcodes_ddService } from './Invoices.datasource.index';
import { Invoices_ds_carrier_service_types_ddService } from './Invoices.datasource.index';
import { Invoices_ds_commodity_price_flat_gridService } from './Invoices.datasource.index';
import { Invoices_ds_commodity_price_gridService } from './Invoices.datasource.index';
import { Invoices_ds_currencies_ddService } from './Invoices.datasource.index';
import { Invoices_ds_diverted_projects_ddService } from './Invoices.datasource.index';
import { Invoices_ds_entity_import_requestsService } from './Invoices.datasource.index';
import { Invoices_ds_find_invoicesService } from './Invoices.datasource.index';
import { Invoices_ds_get_active_billing_contractsService } from './Invoices.datasource.index';
import { Invoices_ds_get_billing_contract_by_idService } from './Invoices.datasource.index';
import { Invoices_ds_get_billing_contract_line_by_billingContractLineIdService } from './Invoices.datasource.index';
import { Invoices_ds_get_billing_contract_lines_by_billingContractIdService } from './Invoices.datasource.index';
import { Invoices_ds_get_billing_projectsService } from './Invoices.datasource.index';
import { Invoices_ds_get_billing_rates_by_billingContractLineIdService } from './Invoices.datasource.index';
import { Invoices_ds_get_billing_record_by_billingTaskIdService } from './Invoices.datasource.index';
import { Invoices_ds_get_billing_tasksService } from './Invoices.datasource.index';
import { Invoices_ds_get_billing_tasks_by_invoiceLineIdService } from './Invoices.datasource.index';
import { Invoices_ds_get_billing_tasks_entitiesService } from './Invoices.datasource.index';
import { Invoices_ds_get_billing_tasks_projectsService } from './Invoices.datasource.index';
import { Invoices_ds_get_billingrecords_by_invoiceLineIdService } from './Invoices.datasource.index';
import { Invoices_ds_get_current_billing_contract_by_projectIdService } from './Invoices.datasource.index';
import { Invoices_ds_get_first_warehouseService } from './Invoices.datasource.index';
import { Invoices_ds_get_first_warehouse_address_by_invoiceIdService } from './Invoices.datasource.index';
import { Invoices_ds_get_invoice_by_invoiceIdService } from './Invoices.datasource.index';
import { Invoices_ds_get_invoice_header_by_invoiceIdService } from './Invoices.datasource.index';
import { Invoices_ds_get_invoice_line_details_by_invoiceIdService } from './Invoices.datasource.index';
import { Invoices_ds_get_invoice_line_details_by_invoiceId_neService } from './Invoices.datasource.index';
import { Invoices_ds_get_invoice_lines_for_report_by_invoiceIdService } from './Invoices.datasource.index';
import { Invoices_ds_get_invoice_lines_grouped_by_invoiceIdService } from './Invoices.datasource.index';
import { Invoices_ds_get_invoice_termsService } from './Invoices.datasource.index';
import { Invoices_ds_get_invoiceline_by_invoiceLineIdService } from './Invoices.datasource.index';
import { Invoices_ds_get_invoicelines_by_invoiceId_bottom1Service } from './Invoices.datasource.index';
import { Invoices_ds_get_invoicestatus_by_statusIdService } from './Invoices.datasource.index';
import { Invoices_ds_get_invoicing_project_rule_prioritiesService } from './Invoices.datasource.index';
import { Invoices_ds_get_last_invoice_line_by_invoiceIdService } from './Invoices.datasource.index';
import { Invoices_ds_get_order_by_orderIdService } from './Invoices.datasource.index';
import { Invoices_ds_get_owner_address_by_invoiceIdService } from './Invoices.datasource.index';
import { Invoices_ds_get_project_divert_billing_by_idService } from './Invoices.datasource.index';
import { Invoices_ds_get_projects_with_unassigned_billing_recordsService } from './Invoices.datasource.index';
import { Invoices_ds_get_reasoncodes_by_lookupcode_parentId_parentEntityIdService } from './Invoices.datasource.index';
import { Invoices_ds_get_warehouse_address_by_warehouseIdService } from './Invoices.datasource.index';
import { Invoices_ds_invoice_editorService } from './Invoices.datasource.index';
import { Invoices_ds_invoice_lines_gridService } from './Invoices.datasource.index';
import { Invoices_ds_invoice_statuses_ddService } from './Invoices.datasource.index';
import { Invoices_ds_invoice_terms_ddService } from './Invoices.datasource.index';
import { Invoices_ds_invoice_total_billed_widgetService } from './Invoices.datasource.index';
import { Invoices_ds_invoice_total_billed_with_filters_widgetService } from './Invoices.datasource.index';
import { Invoices_ds_invoice_total_units_widgetService } from './Invoices.datasource.index';
import { Invoices_ds_invoice_total_units_with_filters_widgetService } from './Invoices.datasource.index';
import { Invoices_ds_invoice_types_ddService } from './Invoices.datasource.index';
import { Invoices_ds_invoices_ddService } from './Invoices.datasource.index';
import { Invoices_ds_invoices_gridService } from './Invoices.datasource.index';
import { Invoices_ds_invoicing_instructionsService } from './Invoices.datasource.index';
import { Invoices_ds_invoicing_instructions_ddService } from './Invoices.datasource.index';
import { Invoices_ds_invoicing_logsService } from './Invoices.datasource.index';
import { Invoices_ds_invoicing_optionsService } from './Invoices.datasource.index';
import { Invoices_ds_invoicing_rule_template_exceptions_gridService } from './Invoices.datasource.index';
import { Invoices_ds_invoicing_transactionsService } from './Invoices.datasource.index';
import { Invoices_ds_location_storage_categories_ddService } from './Invoices.datasource.index';
import { Invoices_ds_measurement_units_ddService } from './Invoices.datasource.index';
import { Invoices_ds_operation_codes_ddService } from './Invoices.datasource.index';
import { Invoices_ds_owners_ddService } from './Invoices.datasource.index';
import { Invoices_ds_projects_ddService } from './Invoices.datasource.index';
import { Invoices_ds_reasoncodes_ddService } from './Invoices.datasource.index';
import { Invoices_ds_recurring_storage_billing_objects_ddService } from './Invoices.datasource.index';
import { Invoices_ds_recurring_storage_schedule_types_ddService } from './Invoices.datasource.index';
import { Invoices_ds_renewal_types_ddService } from './Invoices.datasource.index';
import { Invoices_ds_tags_ddService } from './Invoices.datasource.index';
import { Invoices_ds_tax_schedules_ddService } from './Invoices.datasource.index';
import { Invoices_ds_warehouses_ddService } from './Invoices.datasource.index';

@Injectable({ providedIn: 'root' })
export class Invoices_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public Invoices: Invoices_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_auto_invoicing_rule_group_by_options: Invoices_ds_auto_invoicing_rule_group_by_optionsService;
  public get ds_auto_invoicing_rule_group_by_options(): Invoices_ds_auto_invoicing_rule_group_by_optionsService {
    if(!this._ds_auto_invoicing_rule_group_by_options) {
      this._ds_auto_invoicing_rule_group_by_options = this.injector.get(Invoices_ds_auto_invoicing_rule_group_by_optionsService);
    }
    return this._ds_auto_invoicing_rule_group_by_options;
  }
  private _ds_auto_invoicing_rules_editor: Invoices_ds_auto_invoicing_rules_editorService;
  public get ds_auto_invoicing_rules_editor(): Invoices_ds_auto_invoicing_rules_editorService {
    if(!this._ds_auto_invoicing_rules_editor) {
      this._ds_auto_invoicing_rules_editor = this.injector.get(Invoices_ds_auto_invoicing_rules_editorService);
    }
    return this._ds_auto_invoicing_rules_editor;
  }
  private _ds_billing_aggregation_strategies_dd: Invoices_ds_billing_aggregation_strategies_ddService;
  public get ds_billing_aggregation_strategies_dd(): Invoices_ds_billing_aggregation_strategies_ddService {
    if(!this._ds_billing_aggregation_strategies_dd) {
      this._ds_billing_aggregation_strategies_dd = this.injector.get(Invoices_ds_billing_aggregation_strategies_ddService);
    }
    return this._ds_billing_aggregation_strategies_dd;
  }
  private _ds_billing_contract_accessorial_charges_template_editor: Invoices_ds_billing_contract_accessorial_charges_template_editorService;
  public get ds_billing_contract_accessorial_charges_template_editor(): Invoices_ds_billing_contract_accessorial_charges_template_editorService {
    if(!this._ds_billing_contract_accessorial_charges_template_editor) {
      this._ds_billing_contract_accessorial_charges_template_editor = this.injector.get(Invoices_ds_billing_contract_accessorial_charges_template_editorService);
    }
    return this._ds_billing_contract_accessorial_charges_template_editor;
  }
  private _ds_billing_contract_accessorial_charges_template_lines_grid: Invoices_ds_billing_contract_accessorial_charges_template_lines_gridService;
  public get ds_billing_contract_accessorial_charges_template_lines_grid(): Invoices_ds_billing_contract_accessorial_charges_template_lines_gridService {
    if(!this._ds_billing_contract_accessorial_charges_template_lines_grid) {
      this._ds_billing_contract_accessorial_charges_template_lines_grid = this.injector.get(Invoices_ds_billing_contract_accessorial_charges_template_lines_gridService);
    }
    return this._ds_billing_contract_accessorial_charges_template_lines_grid;
  }
  private _ds_billing_contract_accessorial_charges_templates_grid: Invoices_ds_billing_contract_accessorial_charges_templates_gridService;
  public get ds_billing_contract_accessorial_charges_templates_grid(): Invoices_ds_billing_contract_accessorial_charges_templates_gridService {
    if(!this._ds_billing_contract_accessorial_charges_templates_grid) {
      this._ds_billing_contract_accessorial_charges_templates_grid = this.injector.get(Invoices_ds_billing_contract_accessorial_charges_templates_gridService);
    }
    return this._ds_billing_contract_accessorial_charges_templates_grid;
  }
  private _ds_billing_contract_auto_renew_types_dd: Invoices_ds_billing_contract_auto_renew_types_ddService;
  public get ds_billing_contract_auto_renew_types_dd(): Invoices_ds_billing_contract_auto_renew_types_ddService {
    if(!this._ds_billing_contract_auto_renew_types_dd) {
      this._ds_billing_contract_auto_renew_types_dd = this.injector.get(Invoices_ds_billing_contract_auto_renew_types_ddService);
    }
    return this._ds_billing_contract_auto_renew_types_dd;
  }
  private _ds_billing_contract_line_details_grid: Invoices_ds_billing_contract_line_details_gridService;
  public get ds_billing_contract_line_details_grid(): Invoices_ds_billing_contract_line_details_gridService {
    if(!this._ds_billing_contract_line_details_grid) {
      this._ds_billing_contract_line_details_grid = this.injector.get(Invoices_ds_billing_contract_line_details_gridService);
    }
    return this._ds_billing_contract_line_details_grid;
  }
  private _ds_billing_contract_line_details_validation: Invoices_ds_billing_contract_line_details_validationService;
  public get ds_billing_contract_line_details_validation(): Invoices_ds_billing_contract_line_details_validationService {
    if(!this._ds_billing_contract_line_details_validation) {
      this._ds_billing_contract_line_details_validation = this.injector.get(Invoices_ds_billing_contract_line_details_validationService);
    }
    return this._ds_billing_contract_line_details_validation;
  }
  private _ds_billing_contract_lines_count_widget: Invoices_ds_billing_contract_lines_count_widgetService;
  public get ds_billing_contract_lines_count_widget(): Invoices_ds_billing_contract_lines_count_widgetService {
    if(!this._ds_billing_contract_lines_count_widget) {
      this._ds_billing_contract_lines_count_widget = this.injector.get(Invoices_ds_billing_contract_lines_count_widgetService);
    }
    return this._ds_billing_contract_lines_count_widget;
  }
  private _ds_billing_contract_lines_editor: Invoices_ds_billing_contract_lines_editorService;
  public get ds_billing_contract_lines_editor(): Invoices_ds_billing_contract_lines_editorService {
    if(!this._ds_billing_contract_lines_editor) {
      this._ds_billing_contract_lines_editor = this.injector.get(Invoices_ds_billing_contract_lines_editorService);
    }
    return this._ds_billing_contract_lines_editor;
  }
  private _ds_billing_contract_lines_grid: Invoices_ds_billing_contract_lines_gridService;
  public get ds_billing_contract_lines_grid(): Invoices_ds_billing_contract_lines_gridService {
    if(!this._ds_billing_contract_lines_grid) {
      this._ds_billing_contract_lines_grid = this.injector.get(Invoices_ds_billing_contract_lines_gridService);
    }
    return this._ds_billing_contract_lines_grid;
  }
  private _ds_billing_contract_lines_max_line_number: Invoices_ds_billing_contract_lines_max_line_numberService;
  public get ds_billing_contract_lines_max_line_number(): Invoices_ds_billing_contract_lines_max_line_numberService {
    if(!this._ds_billing_contract_lines_max_line_number) {
      this._ds_billing_contract_lines_max_line_number = this.injector.get(Invoices_ds_billing_contract_lines_max_line_numberService);
    }
    return this._ds_billing_contract_lines_max_line_number;
  }
  private _ds_billing_contract_lines_validation: Invoices_ds_billing_contract_lines_validationService;
  public get ds_billing_contract_lines_validation(): Invoices_ds_billing_contract_lines_validationService {
    if(!this._ds_billing_contract_lines_validation) {
      this._ds_billing_contract_lines_validation = this.injector.get(Invoices_ds_billing_contract_lines_validationService);
    }
    return this._ds_billing_contract_lines_validation;
  }
  private _ds_billing_contract_total_widget: Invoices_ds_billing_contract_total_widgetService;
  public get ds_billing_contract_total_widget(): Invoices_ds_billing_contract_total_widgetService {
    if(!this._ds_billing_contract_total_widget) {
      this._ds_billing_contract_total_widget = this.injector.get(Invoices_ds_billing_contract_total_widgetService);
    }
    return this._ds_billing_contract_total_widget;
  }
  private _ds_billing_contracts_editor: Invoices_ds_billing_contracts_editorService;
  public get ds_billing_contracts_editor(): Invoices_ds_billing_contracts_editorService {
    if(!this._ds_billing_contracts_editor) {
      this._ds_billing_contracts_editor = this.injector.get(Invoices_ds_billing_contracts_editorService);
    }
    return this._ds_billing_contracts_editor;
  }
  private _ds_billing_contracts_grid: Invoices_ds_billing_contracts_gridService;
  public get ds_billing_contracts_grid(): Invoices_ds_billing_contracts_gridService {
    if(!this._ds_billing_contracts_grid) {
      this._ds_billing_contracts_grid = this.injector.get(Invoices_ds_billing_contracts_gridService);
    }
    return this._ds_billing_contracts_grid;
  }
  private _ds_billing_contracts_statuses_dd: Invoices_ds_billing_contracts_statuses_ddService;
  public get ds_billing_contracts_statuses_dd(): Invoices_ds_billing_contracts_statuses_ddService {
    if(!this._ds_billing_contracts_statuses_dd) {
      this._ds_billing_contracts_statuses_dd = this.injector.get(Invoices_ds_billing_contracts_statuses_ddService);
    }
    return this._ds_billing_contracts_statuses_dd;
  }
  private _ds_billing_methods_dd: Invoices_ds_billing_methods_ddService;
  public get ds_billing_methods_dd(): Invoices_ds_billing_methods_ddService {
    if(!this._ds_billing_methods_dd) {
      this._ds_billing_methods_dd = this.injector.get(Invoices_ds_billing_methods_ddService);
    }
    return this._ds_billing_methods_dd;
  }
  private _ds_billing_outbound_shipment_service_type_methods_dd: Invoices_ds_billing_outbound_shipment_service_type_methods_ddService;
  public get ds_billing_outbound_shipment_service_type_methods_dd(): Invoices_ds_billing_outbound_shipment_service_type_methods_ddService {
    if(!this._ds_billing_outbound_shipment_service_type_methods_dd) {
      this._ds_billing_outbound_shipment_service_type_methods_dd = this.injector.get(Invoices_ds_billing_outbound_shipment_service_type_methods_ddService);
    }
    return this._ds_billing_outbound_shipment_service_type_methods_dd;
  }
  private _ds_billing_projects_grid: Invoices_ds_billing_projects_gridService;
  public get ds_billing_projects_grid(): Invoices_ds_billing_projects_gridService {
    if(!this._ds_billing_projects_grid) {
      this._ds_billing_projects_grid = this.injector.get(Invoices_ds_billing_projects_gridService);
    }
    return this._ds_billing_projects_grid;
  }
  private _ds_billing_rates_grid: Invoices_ds_billing_rates_gridService;
  public get ds_billing_rates_grid(): Invoices_ds_billing_rates_gridService {
    if(!this._ds_billing_rates_grid) {
      this._ds_billing_rates_grid = this.injector.get(Invoices_ds_billing_rates_gridService);
    }
    return this._ds_billing_rates_grid;
  }
  private _ds_billing_record_activity_list: Invoices_ds_billing_record_activity_listService;
  public get ds_billing_record_activity_list(): Invoices_ds_billing_record_activity_listService {
    if(!this._ds_billing_record_activity_list) {
      this._ds_billing_record_activity_list = this.injector.get(Invoices_ds_billing_record_activity_listService);
    }
    return this._ds_billing_record_activity_list;
  }
  private _ds_billing_record_editor: Invoices_ds_billing_record_editorService;
  public get ds_billing_record_editor(): Invoices_ds_billing_record_editorService {
    if(!this._ds_billing_record_editor) {
      this._ds_billing_record_editor = this.injector.get(Invoices_ds_billing_record_editorService);
    }
    return this._ds_billing_record_editor;
  }
  private _ds_billing_records_by_invoiceline_grid: Invoices_ds_billing_records_by_invoiceline_gridService;
  public get ds_billing_records_by_invoiceline_grid(): Invoices_ds_billing_records_by_invoiceline_gridService {
    if(!this._ds_billing_records_by_invoiceline_grid) {
      this._ds_billing_records_by_invoiceline_grid = this.injector.get(Invoices_ds_billing_records_by_invoiceline_gridService);
    }
    return this._ds_billing_records_by_invoiceline_grid;
  }
  private _ds_billing_records_grid: Invoices_ds_billing_records_gridService;
  public get ds_billing_records_grid(): Invoices_ds_billing_records_gridService {
    if(!this._ds_billing_records_grid) {
      this._ds_billing_records_grid = this.injector.get(Invoices_ds_billing_records_gridService);
    }
    return this._ds_billing_records_grid;
  }
  private _ds_billing_records_total_billed_by_current_month_widget: Invoices_ds_billing_records_total_billed_by_current_month_widgetService;
  public get ds_billing_records_total_billed_by_current_month_widget(): Invoices_ds_billing_records_total_billed_by_current_month_widgetService {
    if(!this._ds_billing_records_total_billed_by_current_month_widget) {
      this._ds_billing_records_total_billed_by_current_month_widget = this.injector.get(Invoices_ds_billing_records_total_billed_by_current_month_widgetService);
    }
    return this._ds_billing_records_total_billed_by_current_month_widget;
  }
  private _ds_billing_records_total_billed_by_date_widget: Invoices_ds_billing_records_total_billed_by_date_widgetService;
  public get ds_billing_records_total_billed_by_date_widget(): Invoices_ds_billing_records_total_billed_by_date_widgetService {
    if(!this._ds_billing_records_total_billed_by_date_widget) {
      this._ds_billing_records_total_billed_by_date_widget = this.injector.get(Invoices_ds_billing_records_total_billed_by_date_widgetService);
    }
    return this._ds_billing_records_total_billed_by_date_widget;
  }
  private _ds_billing_records_total_invoiced_by_date_widget: Invoices_ds_billing_records_total_invoiced_by_date_widgetService;
  public get ds_billing_records_total_invoiced_by_date_widget(): Invoices_ds_billing_records_total_invoiced_by_date_widgetService {
    if(!this._ds_billing_records_total_invoiced_by_date_widget) {
      this._ds_billing_records_total_invoiced_by_date_widget = this.injector.get(Invoices_ds_billing_records_total_invoiced_by_date_widgetService);
    }
    return this._ds_billing_records_total_invoiced_by_date_widget;
  }
  private _ds_billing_supplemental_fees_grid: Invoices_ds_billing_supplemental_fees_gridService;
  public get ds_billing_supplemental_fees_grid(): Invoices_ds_billing_supplemental_fees_gridService {
    if(!this._ds_billing_supplemental_fees_grid) {
      this._ds_billing_supplemental_fees_grid = this.injector.get(Invoices_ds_billing_supplemental_fees_gridService);
    }
    return this._ds_billing_supplemental_fees_grid;
  }
  private _ds_billingcodes_dd: Invoices_ds_billingcodes_ddService;
  public get ds_billingcodes_dd(): Invoices_ds_billingcodes_ddService {
    if(!this._ds_billingcodes_dd) {
      this._ds_billingcodes_dd = this.injector.get(Invoices_ds_billingcodes_ddService);
    }
    return this._ds_billingcodes_dd;
  }
  private _ds_carrier_service_types_dd: Invoices_ds_carrier_service_types_ddService;
  public get ds_carrier_service_types_dd(): Invoices_ds_carrier_service_types_ddService {
    if(!this._ds_carrier_service_types_dd) {
      this._ds_carrier_service_types_dd = this.injector.get(Invoices_ds_carrier_service_types_ddService);
    }
    return this._ds_carrier_service_types_dd;
  }
  private _ds_commodity_price_flat_grid: Invoices_ds_commodity_price_flat_gridService;
  public get ds_commodity_price_flat_grid(): Invoices_ds_commodity_price_flat_gridService {
    if(!this._ds_commodity_price_flat_grid) {
      this._ds_commodity_price_flat_grid = this.injector.get(Invoices_ds_commodity_price_flat_gridService);
    }
    return this._ds_commodity_price_flat_grid;
  }
  private _ds_commodity_price_grid: Invoices_ds_commodity_price_gridService;
  public get ds_commodity_price_grid(): Invoices_ds_commodity_price_gridService {
    if(!this._ds_commodity_price_grid) {
      this._ds_commodity_price_grid = this.injector.get(Invoices_ds_commodity_price_gridService);
    }
    return this._ds_commodity_price_grid;
  }
  private _ds_currencies_dd: Invoices_ds_currencies_ddService;
  public get ds_currencies_dd(): Invoices_ds_currencies_ddService {
    if(!this._ds_currencies_dd) {
      this._ds_currencies_dd = this.injector.get(Invoices_ds_currencies_ddService);
    }
    return this._ds_currencies_dd;
  }
  private _ds_diverted_projects_dd: Invoices_ds_diverted_projects_ddService;
  public get ds_diverted_projects_dd(): Invoices_ds_diverted_projects_ddService {
    if(!this._ds_diverted_projects_dd) {
      this._ds_diverted_projects_dd = this.injector.get(Invoices_ds_diverted_projects_ddService);
    }
    return this._ds_diverted_projects_dd;
  }
  private _ds_entity_import_requests: Invoices_ds_entity_import_requestsService;
  public get ds_entity_import_requests(): Invoices_ds_entity_import_requestsService {
    if(!this._ds_entity_import_requests) {
      this._ds_entity_import_requests = this.injector.get(Invoices_ds_entity_import_requestsService);
    }
    return this._ds_entity_import_requests;
  }
  private _ds_find_invoices: Invoices_ds_find_invoicesService;
  public get ds_find_invoices(): Invoices_ds_find_invoicesService {
    if(!this._ds_find_invoices) {
      this._ds_find_invoices = this.injector.get(Invoices_ds_find_invoicesService);
    }
    return this._ds_find_invoices;
  }
  private _ds_get_active_billing_contracts: Invoices_ds_get_active_billing_contractsService;
  public get ds_get_active_billing_contracts(): Invoices_ds_get_active_billing_contractsService {
    if(!this._ds_get_active_billing_contracts) {
      this._ds_get_active_billing_contracts = this.injector.get(Invoices_ds_get_active_billing_contractsService);
    }
    return this._ds_get_active_billing_contracts;
  }
  private _ds_get_billing_contract_by_id: Invoices_ds_get_billing_contract_by_idService;
  public get ds_get_billing_contract_by_id(): Invoices_ds_get_billing_contract_by_idService {
    if(!this._ds_get_billing_contract_by_id) {
      this._ds_get_billing_contract_by_id = this.injector.get(Invoices_ds_get_billing_contract_by_idService);
    }
    return this._ds_get_billing_contract_by_id;
  }
  private _ds_get_billing_contract_line_by_billingContractLineId: Invoices_ds_get_billing_contract_line_by_billingContractLineIdService;
  public get ds_get_billing_contract_line_by_billingContractLineId(): Invoices_ds_get_billing_contract_line_by_billingContractLineIdService {
    if(!this._ds_get_billing_contract_line_by_billingContractLineId) {
      this._ds_get_billing_contract_line_by_billingContractLineId = this.injector.get(Invoices_ds_get_billing_contract_line_by_billingContractLineIdService);
    }
    return this._ds_get_billing_contract_line_by_billingContractLineId;
  }
  private _ds_get_billing_contract_lines_by_billingContractId: Invoices_ds_get_billing_contract_lines_by_billingContractIdService;
  public get ds_get_billing_contract_lines_by_billingContractId(): Invoices_ds_get_billing_contract_lines_by_billingContractIdService {
    if(!this._ds_get_billing_contract_lines_by_billingContractId) {
      this._ds_get_billing_contract_lines_by_billingContractId = this.injector.get(Invoices_ds_get_billing_contract_lines_by_billingContractIdService);
    }
    return this._ds_get_billing_contract_lines_by_billingContractId;
  }
  private _ds_get_billing_projects: Invoices_ds_get_billing_projectsService;
  public get ds_get_billing_projects(): Invoices_ds_get_billing_projectsService {
    if(!this._ds_get_billing_projects) {
      this._ds_get_billing_projects = this.injector.get(Invoices_ds_get_billing_projectsService);
    }
    return this._ds_get_billing_projects;
  }
  private _ds_get_billing_rates_by_billingContractLineId: Invoices_ds_get_billing_rates_by_billingContractLineIdService;
  public get ds_get_billing_rates_by_billingContractLineId(): Invoices_ds_get_billing_rates_by_billingContractLineIdService {
    if(!this._ds_get_billing_rates_by_billingContractLineId) {
      this._ds_get_billing_rates_by_billingContractLineId = this.injector.get(Invoices_ds_get_billing_rates_by_billingContractLineIdService);
    }
    return this._ds_get_billing_rates_by_billingContractLineId;
  }
  private _ds_get_billing_record_by_billingTaskId: Invoices_ds_get_billing_record_by_billingTaskIdService;
  public get ds_get_billing_record_by_billingTaskId(): Invoices_ds_get_billing_record_by_billingTaskIdService {
    if(!this._ds_get_billing_record_by_billingTaskId) {
      this._ds_get_billing_record_by_billingTaskId = this.injector.get(Invoices_ds_get_billing_record_by_billingTaskIdService);
    }
    return this._ds_get_billing_record_by_billingTaskId;
  }
  private _ds_get_billing_tasks: Invoices_ds_get_billing_tasksService;
  public get ds_get_billing_tasks(): Invoices_ds_get_billing_tasksService {
    if(!this._ds_get_billing_tasks) {
      this._ds_get_billing_tasks = this.injector.get(Invoices_ds_get_billing_tasksService);
    }
    return this._ds_get_billing_tasks;
  }
  private _ds_get_billing_tasks_by_invoiceLineId: Invoices_ds_get_billing_tasks_by_invoiceLineIdService;
  public get ds_get_billing_tasks_by_invoiceLineId(): Invoices_ds_get_billing_tasks_by_invoiceLineIdService {
    if(!this._ds_get_billing_tasks_by_invoiceLineId) {
      this._ds_get_billing_tasks_by_invoiceLineId = this.injector.get(Invoices_ds_get_billing_tasks_by_invoiceLineIdService);
    }
    return this._ds_get_billing_tasks_by_invoiceLineId;
  }
  private _ds_get_billing_tasks_entities: Invoices_ds_get_billing_tasks_entitiesService;
  public get ds_get_billing_tasks_entities(): Invoices_ds_get_billing_tasks_entitiesService {
    if(!this._ds_get_billing_tasks_entities) {
      this._ds_get_billing_tasks_entities = this.injector.get(Invoices_ds_get_billing_tasks_entitiesService);
    }
    return this._ds_get_billing_tasks_entities;
  }
  private _ds_get_billing_tasks_projects: Invoices_ds_get_billing_tasks_projectsService;
  public get ds_get_billing_tasks_projects(): Invoices_ds_get_billing_tasks_projectsService {
    if(!this._ds_get_billing_tasks_projects) {
      this._ds_get_billing_tasks_projects = this.injector.get(Invoices_ds_get_billing_tasks_projectsService);
    }
    return this._ds_get_billing_tasks_projects;
  }
  private _ds_get_billingrecords_by_invoiceLineId: Invoices_ds_get_billingrecords_by_invoiceLineIdService;
  public get ds_get_billingrecords_by_invoiceLineId(): Invoices_ds_get_billingrecords_by_invoiceLineIdService {
    if(!this._ds_get_billingrecords_by_invoiceLineId) {
      this._ds_get_billingrecords_by_invoiceLineId = this.injector.get(Invoices_ds_get_billingrecords_by_invoiceLineIdService);
    }
    return this._ds_get_billingrecords_by_invoiceLineId;
  }
  private _ds_get_current_billing_contract_by_projectId: Invoices_ds_get_current_billing_contract_by_projectIdService;
  public get ds_get_current_billing_contract_by_projectId(): Invoices_ds_get_current_billing_contract_by_projectIdService {
    if(!this._ds_get_current_billing_contract_by_projectId) {
      this._ds_get_current_billing_contract_by_projectId = this.injector.get(Invoices_ds_get_current_billing_contract_by_projectIdService);
    }
    return this._ds_get_current_billing_contract_by_projectId;
  }
  private _ds_get_first_warehouse: Invoices_ds_get_first_warehouseService;
  public get ds_get_first_warehouse(): Invoices_ds_get_first_warehouseService {
    if(!this._ds_get_first_warehouse) {
      this._ds_get_first_warehouse = this.injector.get(Invoices_ds_get_first_warehouseService);
    }
    return this._ds_get_first_warehouse;
  }
  private _ds_get_first_warehouse_address_by_invoiceId: Invoices_ds_get_first_warehouse_address_by_invoiceIdService;
  public get ds_get_first_warehouse_address_by_invoiceId(): Invoices_ds_get_first_warehouse_address_by_invoiceIdService {
    if(!this._ds_get_first_warehouse_address_by_invoiceId) {
      this._ds_get_first_warehouse_address_by_invoiceId = this.injector.get(Invoices_ds_get_first_warehouse_address_by_invoiceIdService);
    }
    return this._ds_get_first_warehouse_address_by_invoiceId;
  }
  private _ds_get_invoice_by_invoiceId: Invoices_ds_get_invoice_by_invoiceIdService;
  public get ds_get_invoice_by_invoiceId(): Invoices_ds_get_invoice_by_invoiceIdService {
    if(!this._ds_get_invoice_by_invoiceId) {
      this._ds_get_invoice_by_invoiceId = this.injector.get(Invoices_ds_get_invoice_by_invoiceIdService);
    }
    return this._ds_get_invoice_by_invoiceId;
  }
  private _ds_get_invoice_header_by_invoiceId: Invoices_ds_get_invoice_header_by_invoiceIdService;
  public get ds_get_invoice_header_by_invoiceId(): Invoices_ds_get_invoice_header_by_invoiceIdService {
    if(!this._ds_get_invoice_header_by_invoiceId) {
      this._ds_get_invoice_header_by_invoiceId = this.injector.get(Invoices_ds_get_invoice_header_by_invoiceIdService);
    }
    return this._ds_get_invoice_header_by_invoiceId;
  }
  private _ds_get_invoice_line_details_by_invoiceId: Invoices_ds_get_invoice_line_details_by_invoiceIdService;
  public get ds_get_invoice_line_details_by_invoiceId(): Invoices_ds_get_invoice_line_details_by_invoiceIdService {
    if(!this._ds_get_invoice_line_details_by_invoiceId) {
      this._ds_get_invoice_line_details_by_invoiceId = this.injector.get(Invoices_ds_get_invoice_line_details_by_invoiceIdService);
    }
    return this._ds_get_invoice_line_details_by_invoiceId;
  }
  private _ds_get_invoice_line_details_by_invoiceId_ne: Invoices_ds_get_invoice_line_details_by_invoiceId_neService;
  public get ds_get_invoice_line_details_by_invoiceId_ne(): Invoices_ds_get_invoice_line_details_by_invoiceId_neService {
    if(!this._ds_get_invoice_line_details_by_invoiceId_ne) {
      this._ds_get_invoice_line_details_by_invoiceId_ne = this.injector.get(Invoices_ds_get_invoice_line_details_by_invoiceId_neService);
    }
    return this._ds_get_invoice_line_details_by_invoiceId_ne;
  }
  private _ds_get_invoice_lines_for_report_by_invoiceId: Invoices_ds_get_invoice_lines_for_report_by_invoiceIdService;
  public get ds_get_invoice_lines_for_report_by_invoiceId(): Invoices_ds_get_invoice_lines_for_report_by_invoiceIdService {
    if(!this._ds_get_invoice_lines_for_report_by_invoiceId) {
      this._ds_get_invoice_lines_for_report_by_invoiceId = this.injector.get(Invoices_ds_get_invoice_lines_for_report_by_invoiceIdService);
    }
    return this._ds_get_invoice_lines_for_report_by_invoiceId;
  }
  private _ds_get_invoice_lines_grouped_by_invoiceId: Invoices_ds_get_invoice_lines_grouped_by_invoiceIdService;
  public get ds_get_invoice_lines_grouped_by_invoiceId(): Invoices_ds_get_invoice_lines_grouped_by_invoiceIdService {
    if(!this._ds_get_invoice_lines_grouped_by_invoiceId) {
      this._ds_get_invoice_lines_grouped_by_invoiceId = this.injector.get(Invoices_ds_get_invoice_lines_grouped_by_invoiceIdService);
    }
    return this._ds_get_invoice_lines_grouped_by_invoiceId;
  }
  private _ds_get_invoice_terms: Invoices_ds_get_invoice_termsService;
  public get ds_get_invoice_terms(): Invoices_ds_get_invoice_termsService {
    if(!this._ds_get_invoice_terms) {
      this._ds_get_invoice_terms = this.injector.get(Invoices_ds_get_invoice_termsService);
    }
    return this._ds_get_invoice_terms;
  }
  private _ds_get_invoiceline_by_invoiceLineId: Invoices_ds_get_invoiceline_by_invoiceLineIdService;
  public get ds_get_invoiceline_by_invoiceLineId(): Invoices_ds_get_invoiceline_by_invoiceLineIdService {
    if(!this._ds_get_invoiceline_by_invoiceLineId) {
      this._ds_get_invoiceline_by_invoiceLineId = this.injector.get(Invoices_ds_get_invoiceline_by_invoiceLineIdService);
    }
    return this._ds_get_invoiceline_by_invoiceLineId;
  }
  private _ds_get_invoicelines_by_invoiceId_bottom1: Invoices_ds_get_invoicelines_by_invoiceId_bottom1Service;
  public get ds_get_invoicelines_by_invoiceId_bottom1(): Invoices_ds_get_invoicelines_by_invoiceId_bottom1Service {
    if(!this._ds_get_invoicelines_by_invoiceId_bottom1) {
      this._ds_get_invoicelines_by_invoiceId_bottom1 = this.injector.get(Invoices_ds_get_invoicelines_by_invoiceId_bottom1Service);
    }
    return this._ds_get_invoicelines_by_invoiceId_bottom1;
  }
  private _ds_get_invoicestatus_by_statusId: Invoices_ds_get_invoicestatus_by_statusIdService;
  public get ds_get_invoicestatus_by_statusId(): Invoices_ds_get_invoicestatus_by_statusIdService {
    if(!this._ds_get_invoicestatus_by_statusId) {
      this._ds_get_invoicestatus_by_statusId = this.injector.get(Invoices_ds_get_invoicestatus_by_statusIdService);
    }
    return this._ds_get_invoicestatus_by_statusId;
  }
  private _ds_get_invoicing_project_rule_priorities: Invoices_ds_get_invoicing_project_rule_prioritiesService;
  public get ds_get_invoicing_project_rule_priorities(): Invoices_ds_get_invoicing_project_rule_prioritiesService {
    if(!this._ds_get_invoicing_project_rule_priorities) {
      this._ds_get_invoicing_project_rule_priorities = this.injector.get(Invoices_ds_get_invoicing_project_rule_prioritiesService);
    }
    return this._ds_get_invoicing_project_rule_priorities;
  }
  private _ds_get_last_invoice_line_by_invoiceId: Invoices_ds_get_last_invoice_line_by_invoiceIdService;
  public get ds_get_last_invoice_line_by_invoiceId(): Invoices_ds_get_last_invoice_line_by_invoiceIdService {
    if(!this._ds_get_last_invoice_line_by_invoiceId) {
      this._ds_get_last_invoice_line_by_invoiceId = this.injector.get(Invoices_ds_get_last_invoice_line_by_invoiceIdService);
    }
    return this._ds_get_last_invoice_line_by_invoiceId;
  }
  private _ds_get_order_by_orderId: Invoices_ds_get_order_by_orderIdService;
  public get ds_get_order_by_orderId(): Invoices_ds_get_order_by_orderIdService {
    if(!this._ds_get_order_by_orderId) {
      this._ds_get_order_by_orderId = this.injector.get(Invoices_ds_get_order_by_orderIdService);
    }
    return this._ds_get_order_by_orderId;
  }
  private _ds_get_owner_address_by_invoiceId: Invoices_ds_get_owner_address_by_invoiceIdService;
  public get ds_get_owner_address_by_invoiceId(): Invoices_ds_get_owner_address_by_invoiceIdService {
    if(!this._ds_get_owner_address_by_invoiceId) {
      this._ds_get_owner_address_by_invoiceId = this.injector.get(Invoices_ds_get_owner_address_by_invoiceIdService);
    }
    return this._ds_get_owner_address_by_invoiceId;
  }
  private _ds_get_project_divert_billing_by_id: Invoices_ds_get_project_divert_billing_by_idService;
  public get ds_get_project_divert_billing_by_id(): Invoices_ds_get_project_divert_billing_by_idService {
    if(!this._ds_get_project_divert_billing_by_id) {
      this._ds_get_project_divert_billing_by_id = this.injector.get(Invoices_ds_get_project_divert_billing_by_idService);
    }
    return this._ds_get_project_divert_billing_by_id;
  }
  private _ds_get_projects_with_unassigned_billing_records: Invoices_ds_get_projects_with_unassigned_billing_recordsService;
  public get ds_get_projects_with_unassigned_billing_records(): Invoices_ds_get_projects_with_unassigned_billing_recordsService {
    if(!this._ds_get_projects_with_unassigned_billing_records) {
      this._ds_get_projects_with_unassigned_billing_records = this.injector.get(Invoices_ds_get_projects_with_unassigned_billing_recordsService);
    }
    return this._ds_get_projects_with_unassigned_billing_records;
  }
  private _ds_get_reasoncodes_by_lookupcode_parentId_parentEntityId: Invoices_ds_get_reasoncodes_by_lookupcode_parentId_parentEntityIdService;
  public get ds_get_reasoncodes_by_lookupcode_parentId_parentEntityId(): Invoices_ds_get_reasoncodes_by_lookupcode_parentId_parentEntityIdService {
    if(!this._ds_get_reasoncodes_by_lookupcode_parentId_parentEntityId) {
      this._ds_get_reasoncodes_by_lookupcode_parentId_parentEntityId = this.injector.get(Invoices_ds_get_reasoncodes_by_lookupcode_parentId_parentEntityIdService);
    }
    return this._ds_get_reasoncodes_by_lookupcode_parentId_parentEntityId;
  }
  private _ds_get_warehouse_address_by_warehouseId: Invoices_ds_get_warehouse_address_by_warehouseIdService;
  public get ds_get_warehouse_address_by_warehouseId(): Invoices_ds_get_warehouse_address_by_warehouseIdService {
    if(!this._ds_get_warehouse_address_by_warehouseId) {
      this._ds_get_warehouse_address_by_warehouseId = this.injector.get(Invoices_ds_get_warehouse_address_by_warehouseIdService);
    }
    return this._ds_get_warehouse_address_by_warehouseId;
  }
  private _ds_invoice_editor: Invoices_ds_invoice_editorService;
  public get ds_invoice_editor(): Invoices_ds_invoice_editorService {
    if(!this._ds_invoice_editor) {
      this._ds_invoice_editor = this.injector.get(Invoices_ds_invoice_editorService);
    }
    return this._ds_invoice_editor;
  }
  private _ds_invoice_lines_grid: Invoices_ds_invoice_lines_gridService;
  public get ds_invoice_lines_grid(): Invoices_ds_invoice_lines_gridService {
    if(!this._ds_invoice_lines_grid) {
      this._ds_invoice_lines_grid = this.injector.get(Invoices_ds_invoice_lines_gridService);
    }
    return this._ds_invoice_lines_grid;
  }
  private _ds_invoice_statuses_dd: Invoices_ds_invoice_statuses_ddService;
  public get ds_invoice_statuses_dd(): Invoices_ds_invoice_statuses_ddService {
    if(!this._ds_invoice_statuses_dd) {
      this._ds_invoice_statuses_dd = this.injector.get(Invoices_ds_invoice_statuses_ddService);
    }
    return this._ds_invoice_statuses_dd;
  }
  private _ds_invoice_terms_dd: Invoices_ds_invoice_terms_ddService;
  public get ds_invoice_terms_dd(): Invoices_ds_invoice_terms_ddService {
    if(!this._ds_invoice_terms_dd) {
      this._ds_invoice_terms_dd = this.injector.get(Invoices_ds_invoice_terms_ddService);
    }
    return this._ds_invoice_terms_dd;
  }
  private _ds_invoice_total_billed_widget: Invoices_ds_invoice_total_billed_widgetService;
  public get ds_invoice_total_billed_widget(): Invoices_ds_invoice_total_billed_widgetService {
    if(!this._ds_invoice_total_billed_widget) {
      this._ds_invoice_total_billed_widget = this.injector.get(Invoices_ds_invoice_total_billed_widgetService);
    }
    return this._ds_invoice_total_billed_widget;
  }
  private _ds_invoice_total_billed_with_filters_widget: Invoices_ds_invoice_total_billed_with_filters_widgetService;
  public get ds_invoice_total_billed_with_filters_widget(): Invoices_ds_invoice_total_billed_with_filters_widgetService {
    if(!this._ds_invoice_total_billed_with_filters_widget) {
      this._ds_invoice_total_billed_with_filters_widget = this.injector.get(Invoices_ds_invoice_total_billed_with_filters_widgetService);
    }
    return this._ds_invoice_total_billed_with_filters_widget;
  }
  private _ds_invoice_total_units_widget: Invoices_ds_invoice_total_units_widgetService;
  public get ds_invoice_total_units_widget(): Invoices_ds_invoice_total_units_widgetService {
    if(!this._ds_invoice_total_units_widget) {
      this._ds_invoice_total_units_widget = this.injector.get(Invoices_ds_invoice_total_units_widgetService);
    }
    return this._ds_invoice_total_units_widget;
  }
  private _ds_invoice_total_units_with_filters_widget: Invoices_ds_invoice_total_units_with_filters_widgetService;
  public get ds_invoice_total_units_with_filters_widget(): Invoices_ds_invoice_total_units_with_filters_widgetService {
    if(!this._ds_invoice_total_units_with_filters_widget) {
      this._ds_invoice_total_units_with_filters_widget = this.injector.get(Invoices_ds_invoice_total_units_with_filters_widgetService);
    }
    return this._ds_invoice_total_units_with_filters_widget;
  }
  private _ds_invoice_types_dd: Invoices_ds_invoice_types_ddService;
  public get ds_invoice_types_dd(): Invoices_ds_invoice_types_ddService {
    if(!this._ds_invoice_types_dd) {
      this._ds_invoice_types_dd = this.injector.get(Invoices_ds_invoice_types_ddService);
    }
    return this._ds_invoice_types_dd;
  }
  private _ds_invoices_dd: Invoices_ds_invoices_ddService;
  public get ds_invoices_dd(): Invoices_ds_invoices_ddService {
    if(!this._ds_invoices_dd) {
      this._ds_invoices_dd = this.injector.get(Invoices_ds_invoices_ddService);
    }
    return this._ds_invoices_dd;
  }
  private _ds_invoices_grid: Invoices_ds_invoices_gridService;
  public get ds_invoices_grid(): Invoices_ds_invoices_gridService {
    if(!this._ds_invoices_grid) {
      this._ds_invoices_grid = this.injector.get(Invoices_ds_invoices_gridService);
    }
    return this._ds_invoices_grid;
  }
  private _ds_invoicing_instructions: Invoices_ds_invoicing_instructionsService;
  public get ds_invoicing_instructions(): Invoices_ds_invoicing_instructionsService {
    if(!this._ds_invoicing_instructions) {
      this._ds_invoicing_instructions = this.injector.get(Invoices_ds_invoicing_instructionsService);
    }
    return this._ds_invoicing_instructions;
  }
  private _ds_invoicing_instructions_dd: Invoices_ds_invoicing_instructions_ddService;
  public get ds_invoicing_instructions_dd(): Invoices_ds_invoicing_instructions_ddService {
    if(!this._ds_invoicing_instructions_dd) {
      this._ds_invoicing_instructions_dd = this.injector.get(Invoices_ds_invoicing_instructions_ddService);
    }
    return this._ds_invoicing_instructions_dd;
  }
  private _ds_invoicing_logs: Invoices_ds_invoicing_logsService;
  public get ds_invoicing_logs(): Invoices_ds_invoicing_logsService {
    if(!this._ds_invoicing_logs) {
      this._ds_invoicing_logs = this.injector.get(Invoices_ds_invoicing_logsService);
    }
    return this._ds_invoicing_logs;
  }
  private _ds_invoicing_options: Invoices_ds_invoicing_optionsService;
  public get ds_invoicing_options(): Invoices_ds_invoicing_optionsService {
    if(!this._ds_invoicing_options) {
      this._ds_invoicing_options = this.injector.get(Invoices_ds_invoicing_optionsService);
    }
    return this._ds_invoicing_options;
  }
  private _ds_invoicing_rule_template_exceptions_grid: Invoices_ds_invoicing_rule_template_exceptions_gridService;
  public get ds_invoicing_rule_template_exceptions_grid(): Invoices_ds_invoicing_rule_template_exceptions_gridService {
    if(!this._ds_invoicing_rule_template_exceptions_grid) {
      this._ds_invoicing_rule_template_exceptions_grid = this.injector.get(Invoices_ds_invoicing_rule_template_exceptions_gridService);
    }
    return this._ds_invoicing_rule_template_exceptions_grid;
  }
  private _ds_invoicing_transactions: Invoices_ds_invoicing_transactionsService;
  public get ds_invoicing_transactions(): Invoices_ds_invoicing_transactionsService {
    if(!this._ds_invoicing_transactions) {
      this._ds_invoicing_transactions = this.injector.get(Invoices_ds_invoicing_transactionsService);
    }
    return this._ds_invoicing_transactions;
  }
  private _ds_location_storage_categories_dd: Invoices_ds_location_storage_categories_ddService;
  public get ds_location_storage_categories_dd(): Invoices_ds_location_storage_categories_ddService {
    if(!this._ds_location_storage_categories_dd) {
      this._ds_location_storage_categories_dd = this.injector.get(Invoices_ds_location_storage_categories_ddService);
    }
    return this._ds_location_storage_categories_dd;
  }
  private _ds_measurement_units_dd: Invoices_ds_measurement_units_ddService;
  public get ds_measurement_units_dd(): Invoices_ds_measurement_units_ddService {
    if(!this._ds_measurement_units_dd) {
      this._ds_measurement_units_dd = this.injector.get(Invoices_ds_measurement_units_ddService);
    }
    return this._ds_measurement_units_dd;
  }
  private _ds_operation_codes_dd: Invoices_ds_operation_codes_ddService;
  public get ds_operation_codes_dd(): Invoices_ds_operation_codes_ddService {
    if(!this._ds_operation_codes_dd) {
      this._ds_operation_codes_dd = this.injector.get(Invoices_ds_operation_codes_ddService);
    }
    return this._ds_operation_codes_dd;
  }
  private _ds_owners_dd: Invoices_ds_owners_ddService;
  public get ds_owners_dd(): Invoices_ds_owners_ddService {
    if(!this._ds_owners_dd) {
      this._ds_owners_dd = this.injector.get(Invoices_ds_owners_ddService);
    }
    return this._ds_owners_dd;
  }
  private _ds_projects_dd: Invoices_ds_projects_ddService;
  public get ds_projects_dd(): Invoices_ds_projects_ddService {
    if(!this._ds_projects_dd) {
      this._ds_projects_dd = this.injector.get(Invoices_ds_projects_ddService);
    }
    return this._ds_projects_dd;
  }
  private _ds_reasoncodes_dd: Invoices_ds_reasoncodes_ddService;
  public get ds_reasoncodes_dd(): Invoices_ds_reasoncodes_ddService {
    if(!this._ds_reasoncodes_dd) {
      this._ds_reasoncodes_dd = this.injector.get(Invoices_ds_reasoncodes_ddService);
    }
    return this._ds_reasoncodes_dd;
  }
  private _ds_recurring_storage_billing_objects_dd: Invoices_ds_recurring_storage_billing_objects_ddService;
  public get ds_recurring_storage_billing_objects_dd(): Invoices_ds_recurring_storage_billing_objects_ddService {
    if(!this._ds_recurring_storage_billing_objects_dd) {
      this._ds_recurring_storage_billing_objects_dd = this.injector.get(Invoices_ds_recurring_storage_billing_objects_ddService);
    }
    return this._ds_recurring_storage_billing_objects_dd;
  }
  private _ds_recurring_storage_schedule_types_dd: Invoices_ds_recurring_storage_schedule_types_ddService;
  public get ds_recurring_storage_schedule_types_dd(): Invoices_ds_recurring_storage_schedule_types_ddService {
    if(!this._ds_recurring_storage_schedule_types_dd) {
      this._ds_recurring_storage_schedule_types_dd = this.injector.get(Invoices_ds_recurring_storage_schedule_types_ddService);
    }
    return this._ds_recurring_storage_schedule_types_dd;
  }
  private _ds_renewal_types_dd: Invoices_ds_renewal_types_ddService;
  public get ds_renewal_types_dd(): Invoices_ds_renewal_types_ddService {
    if(!this._ds_renewal_types_dd) {
      this._ds_renewal_types_dd = this.injector.get(Invoices_ds_renewal_types_ddService);
    }
    return this._ds_renewal_types_dd;
  }
  private _ds_tags_dd: Invoices_ds_tags_ddService;
  public get ds_tags_dd(): Invoices_ds_tags_ddService {
    if(!this._ds_tags_dd) {
      this._ds_tags_dd = this.injector.get(Invoices_ds_tags_ddService);
    }
    return this._ds_tags_dd;
  }
  private _ds_tax_schedules_dd: Invoices_ds_tax_schedules_ddService;
  public get ds_tax_schedules_dd(): Invoices_ds_tax_schedules_ddService {
    if(!this._ds_tax_schedules_dd) {
      this._ds_tax_schedules_dd = this.injector.get(Invoices_ds_tax_schedules_ddService);
    }
    return this._ds_tax_schedules_dd;
  }
  private _ds_warehouses_dd: Invoices_ds_warehouses_ddService;
  public get ds_warehouses_dd(): Invoices_ds_warehouses_ddService {
    if(!this._ds_warehouses_dd) {
      this._ds_warehouses_dd = this.injector.get(Invoices_ds_warehouses_ddService);
    }
    return this._ds_warehouses_dd;
  }
}

