import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 


import { FootPrintManager_sales_order_lines_gridComponent } from './FootPrintManager.sales_order_lines_grid.component';
import { FootPrintManager_sales_order_picking_gridComponent } from './FootPrintManager.sales_order_picking_grid.component';
import { FootPrintManager_manual_allocations_gridComponent } from './FootPrintManager.manual_allocations_grid.component';
import { FootPrintManager_shippingcontents_gridComponent } from './FootPrintManager.shippingcontents_grid.component';
import { SalesOrders_outbound_bill_of_lading_reportComponent } from './SalesOrders.outbound_bill_of_lading_report.component';
import { SalesOrders_pick_slip_by_orderId_reportComponent } from './SalesOrders.pick_slip_by_orderId_report.component';
import { FootPrintManager_accessorial_tasks_gridComponent } from './FootPrintManager.accessorial_tasks_grid.component';
import { FootPrintManager_billing_records_gridComponent } from './FootPrintManager.billing_records_grid.component';
import { FootPrintManager_orderaddresses_gridComponent } from './FootPrintManager.orderaddresses_grid.component';
import { FootPrintManager_instructions_gridComponent } from './FootPrintManager.instructions_grid.component';
import { FootPrintManager_sales_order_tasks_gridComponent } from './FootPrintManager.sales_order_tasks_grid.component';
import { SalesOrders_order_total_units_widgetComponent } from './SalesOrders.order_total_units_widget.component';
import { SalesOrders_order_total_picked_units_widgetComponent } from './SalesOrders.order_total_picked_units_widget.component';
import { SalesOrders_order_total_picked_gross_widgetComponent } from './SalesOrders.order_total_picked_gross_widget.component';
import { SalesOrders_orderclasses_dd_singleComponent } from './SalesOrders.orderclasses_dd_single.component'
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { Locations_warehouses_dd_singleComponent } from './Locations.warehouses_dd_single.component'
import { Carriers_carriers_ownerscarriers_dd_singleComponent } from './Carriers.carriers_ownerscarriers_dd_single.component'
import { Carriers_carrierservicetype_dd_singleComponent } from './Carriers.carrierservicetype_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_sales_order_lines_gridComponent),
    forwardRef(() => FootPrintManager_sales_order_picking_gridComponent),
    forwardRef(() => FootPrintManager_manual_allocations_gridComponent),
    forwardRef(() => FootPrintManager_shippingcontents_gridComponent),
    forwardRef(() => SalesOrders_outbound_bill_of_lading_reportComponent),
    forwardRef(() => SalesOrders_pick_slip_by_orderId_reportComponent),
    forwardRef(() => FootPrintManager_accessorial_tasks_gridComponent),
    forwardRef(() => FootPrintManager_billing_records_gridComponent),
    forwardRef(() => FootPrintManager_orderaddresses_gridComponent),
    forwardRef(() => FootPrintManager_instructions_gridComponent),
    forwardRef(() => FootPrintManager_sales_order_tasks_gridComponent),
    forwardRef(() => SalesOrders_order_total_units_widgetComponent),
    forwardRef(() => SalesOrders_order_total_picked_units_widgetComponent),
    forwardRef(() => SalesOrders_order_total_picked_gross_widgetComponent),
    forwardRef(() => SalesOrders_orderclasses_dd_singleComponent),
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => Locations_warehouses_dd_singleComponent),
    forwardRef(() => Carriers_carriers_ownerscarriers_dd_singleComponent),
    forwardRef(() => Carriers_carrierservicetype_dd_singleComponent),
  ],
  selector: 'FootPrintManager-sales_order_editor',
  templateUrl: './FootPrintManager.sales_order_editor.component.html'
})
export class FootPrintManager_sales_order_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { orderId: number } = { orderId: null };
  //#region Inputs
  @Input('orderId') set $inParams_orderId(v: number) {
    this.inParams.orderId = v;
  }
  get $inParams_orderId(): number {
    return this.inParams.orderId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { shipmentId?: number, billingAddressId?: number, orderState?: number } = { };
  //#endregion
  entity: { Id?: number, AccountId?: number, BillingAddresId?: number, LookupCode?: string, Notes?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, PreferredCarrierId?: number, PreferredCarrierServiceTypeId?: number, PreferredWarehouseId?: number, ProjectId?: number, RequestedDeliveryDate?: string, VendorReference?: string, Project?: { OwnerId?: number }, OrderClass?: { Name?: string }, Status?: { Name?: string }, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { Id?: number, BillOfLading?: string, BrokerReference?: string, ContainerIdentifier?: string, ConveyanceId?: number, ConveyenceReference?: string, PickupDate?: string, ReferenceNumber?: string, SealId?: string, TrackingIdentifier?: string, TrailerId?: string } }[], OrderLines?: { LineNumber?: number }[], Account?: { Id?: number, AccountsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, Address?: { City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } };

  formGroup: FormGroup = new FormGroup({
    lookupcode: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    order_class: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    owner: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    vendor_reference: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    expected_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    carrier: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    carrier_service: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    reference: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    container_identifier: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    bill_of_lading: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    trailer: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    seal: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    tracking: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    pickup_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      process: new ToolModel(new ButtonModel('process', new ButtonStyles(null, null), false, 'Process', 'ms-Icon ms-Icon--Process')
    ),
      complete: new ToolModel(new ButtonModel('complete', new ButtonStyles(null, null), false, 'Complete', 'icon-ic_fluent_checkmark_circle_20_regular')
    ),
      auto_pick: new ToolModel(new ButtonModel('auto_pick', new ButtonStyles(null, null), false, 'Auto pick', 'icon-datex-Pick')
    ),
      auto_load: new ToolModel(new ButtonModel('auto_load', new ButtonStyles(null, null), false, 'Auto load', 'icon-datex-Load')
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      appointment: new ToolModel(new ButtonModel('appointment', new ButtonStyles(null, null), false, 'Appointment', 'icon-ic_fluent_calendar_ltr_20_regular')
    ),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      revert: new ToolModel(new ButtonModel('revert', new ButtonStyles(null, null), false, 'Revert', 'ms-Icon ms-Icon--ClearSelection')
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular')
    ),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(['destructive'], null), false, 'Delete', 'icon-ic_fluent_delete_20_regular')
    ),
      separator3: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      attachments: new ToolModel(new ButtonModel('attachments', new ButtonStyles(null, null), false, ' ', 'icon-ic_fluent_attach_20_regular')
    ),
      prints: new ToolModel(new SplitButtonModel<{ license_plate_labels: ButtonModel, loadslip: ButtonModel }>(
        'prints',
        new ButtonStyles(null, null),
        false,
        ' ',
        'icon-ic_fluent_print_20_regular',
        [
          new ButtonModel('license_plate_labels', new ButtonStyles(null, null), false, 'License plate labels', ''),
          new ButtonModel('loadslip', new ButtonStyles(null, null), false, 'Load slip report', '')
        ])
    ),
      separator4: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      options: new ToolModel(new SplitButtonModel<{ email_requests: ButtonModel, discussions: ButtonModel, driver_check_out: ButtonModel, pallet_transactions: ButtonModel, surveys: ButtonModel, temperature_readings: ButtonModel, copy_order: ButtonModel }>(
        'options',
        new ButtonStyles(null, null),
        false,
        ' ',
        'icon-ic_fluent_more_horizontal_20_regular',
        [
          new ButtonModel('email_requests', new ButtonStyles(null, null), false, 'Email requests', 'icon-ic_fluent_mail_arrow_up_20_regular'),
          new ButtonModel('discussions', new ButtonStyles(null, null), false, 'Discussions', 'icon-ic_fluent_chat_20_regular'),
          new ButtonModel('driver_check_out', new ButtonStyles(null, null), false, 'Driver check out', 'ms-Icon ms-Icon--InsertSignatureLine'),
          new ButtonModel('pallet_transactions', new ButtonStyles(null, null), false, 'Pallet transactions', 'icon-ic_fluent_arrow_swap_20_regular'),
          new ButtonModel('surveys', new ButtonStyles(null, null), false, 'Surveys', 'ms-Icon ms-Icon--Questionnaire'),
          new ButtonModel('temperature_readings', new ButtonStyles(null, null), false, 'Temperature readings', 'ms-Icon ms-Icon--Frigid'),
          new ButtonModel('copy_order', new ButtonStyles(null, null), false, 'Copy order', 'ms-Icon ms-Icon--Copy')
        ])
    ),
      separator5: new ToolModel(new SeparatorModel(new Styles(null, null))
    )
  };

  fields = {
    lookupcode: new FieldModel(new TextBoxModel(this.formGroup.controls['lookupcode'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Lookup', true)
,
    order_class: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['order_class'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Order class', true)
,
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Owner', true)
,
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Project', true)
,
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Warehouse', true)
,
    order_status: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), 'Order status', false)
,
    order_state: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), 'Order state', false)
,
    integration_information: new FieldModel(new ButtonModel('', new ButtonStyles(['link'], null), false, 'Integration information', '')
, new ControlContainerStyles(null, null), 'Integration information', false)
,
    ship_to_selection: new FieldModel(new ButtonModel('', new ButtonStyles(null, null), false, 'Ship to', 'icon-ic_fluent_person_square_20_regular')
, new ControlContainerStyles(null, null), '', false)
,
    ship_to_clear: new FieldModel(new ButtonModel('', new ButtonStyles(null, null), false, 'Clear', 'ms-Icon ms-Icon--Blocked')
, new ControlContainerStyles(null, null), '', false)
,
    ship_to: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), '', false)
,
    vendor_reference: new FieldModel(new TextBoxModel(this.formGroup.controls['vendor_reference'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Vendor reference', false)
,
    expected_date: new FieldModel(new DateBoxModel(this.formGroup.controls['expected_date'] as DatexFormControl, null, false, 'MM/DD/YYYY', 'datetime')
, new ControlContainerStyles(null, null), 'Expected date', false)
,
    carrier: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['carrier'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Carrier', false)
,
    carrier_service: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['carrier_service'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Service', false)
,
    reference: new FieldModel(new TextBoxModel(this.formGroup.controls['reference'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Owner reference', false)
,
    container_identifier: new FieldModel(new TextBoxModel(this.formGroup.controls['container_identifier'] as DatexFormControl, null, true, '')
, new ControlContainerStyles(null, null), 'Container identifier', false)
,
    bill_of_lading: new FieldModel(new TextBoxModel(this.formGroup.controls['bill_of_lading'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'BOL', false)
,
    trailer: new FieldModel(new TextBoxModel(this.formGroup.controls['trailer'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Trailer number', false)
,
    seal: new FieldModel(new TextBoxModel(this.formGroup.controls['seal'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Seal number', false)
,
    tracking: new FieldModel(new TextBoxModel(this.formGroup.controls['tracking'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Tracking', false)
,
    pickup_date: new FieldModel(new DateBoxModel(this.formGroup.controls['pickup_date'] as DatexFormControl, null, false, 'MM/DD/YYYY', 'datetime')
, new ControlContainerStyles(null, null), 'Pickup date', false)
,
    custom_fields: new FieldModel(new ButtonModel('', new ButtonStyles(null, null), false, 'Custom fields', 'ms-Icon ms-Icon--Edit')
, new ControlContainerStyles(null, null), '', false)
,
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Notes', false)
,
  };

  fieldsets = {
  newGroup1: new FieldsetModel('Order', false, true, true),
  newGroup2: new FieldsetModel('Details', false, true, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
      reports: new TabGroupModel(),
      billing: new TabGroupModel(),
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      sales_order_lines: new TabItemModel(
        this.rootTabGroup, 
        'Lines', 
        ),
      picking_tasks: new TabItemModel(
        this.rootTabGroup, 
        'Pick tasks', 
        ),
      manual_allocation_tasks: new TabItemModel(
        this.rootTabGroup, 
        'Manual tasks', 
        ),
      shipping_contents: new TabItemModel(
        this.rootTabGroup, 
        'Picked', 
        ),
      reports: new TabItemModel(
        this.rootTabGroup, 
        'Reports', 
        true,
        {
          bol_report: new TabItemModel(
          this.subTabGroups.reports, 
          'Bill of Lading', 
          ),
          pick_slip_report: new TabItemModel(
          this.subTabGroups.reports, 
          'Pick Slip', 
          ),
        }
        ),
      billing: new TabItemModel(
        this.rootTabGroup, 
        'Billing', 
        true,
        {
          accessorial_tasks: new TabItemModel(
          this.subTabGroups.billing, 
          'Accessorials', 
          ),
          billing_records: new TabItemModel(
          this.subTabGroups.billing, 
          'Billing Records', 
          ),
        }
        ),
      contacts: new TabItemModel(
        this.rootTabGroup, 
        'Contacts', 
        ),
      instructions: new TabItemModel(
        this.rootTabGroup, 
        'Instructions', 
        ),
      tasks: new TabItemModel(
        this.rootTabGroup, 
        'Activity', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_sales_order_lines_sales_order_lines_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_sales_order_lines_sales_order_lines_grid_inParams_shipmentId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ShipmentOrderLookups[0]?.ShipmentId;
      
      return expr;
    }
  
    get $tabs_sales_order_lines_sales_order_lines_grid_inParams_projectId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ProjectId;
      
      return expr;
    }
  
    get $tabs_sales_order_lines_sales_order_lines_grid_inParams_orderStatusId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.OrderStatusId;
      
      return expr;
    }
  
    get $tabs_sales_order_lines_sales_order_lines_grid_inParams_warehouseId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.PreferredWarehouseId;
      
      return expr;
    }
  
    get $tabs_sales_order_lines_sales_order_lines_grid_inParams_orderState(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.vars.orderState;
      
      return expr;
    }
  
    get $tabs_picking_tasks_sales_order_picking_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_picking_tasks_sales_order_picking_grid_inParams_shipmentId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ShipmentOrderLookups[0]?.ShipmentId;
      
      return expr;
    }
  
    get $tabs_picking_tasks_sales_order_picking_grid_inParams_orderStatusId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.OrderStatusId;
      
      return expr;
    }
  
    get $tabs_manual_allocation_tasks_manual_allocations_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_shipping_contents_shippingcontents_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_shipping_contents_shippingcontents_grid_inParams_shipmentId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.vars.shipmentId;
      
      return expr;
    }
  
    get $tabs_reports_bol_report_outbound_bill_of_lading_report_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_reports_pick_slip_report_pick_slip_by_orderId_report_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_projectId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ProjectId;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_entityStatusId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.OrderStatusId;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_warehouseId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.PreferredWarehouseId;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_shipmentId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ShipmentOrderLookups[0]?.ShipmentId;
      
      return expr;
    }
  
    cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_orderIds: number[];
    get $tabs_billing_billing_records_billing_records_grid_inParams_orderIds(): number[] {
      const $editor = this;
      const $utils = this.utils;
      const expr = [$editor.inParams.orderId];
      
      if(!isEqual(this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_orderIds, expr)) {
        this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_orderIds = expr;
      }
      return this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_orderIds;
    }
  
    get $tabs_billing_billing_records_billing_records_grid_inParams_includeInvoiced(): boolean {
      const $editor = this;
      const $utils = this.utils;
      const expr = true;
      
      return expr;
    }
  
    cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds: number[];
    get $tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds(): number[] {
      const $editor = this;
      const $utils = this.utils;
      const expr = [$editor.entity.ShipmentOrderLookups[0]?.ShipmentId];
      
      if(!isEqual(this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds, expr)) {
        this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds = expr;
      }
      return this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds;
    }
  
    get $tabs_contacts_orderaddresses_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_instructions_instructions_grid_inParams_entityName(): string {
      const $editor = this;
      const $utils = this.utils;
      const expr = 'Order';
      
      return expr;
    }
  
    get $tabs_instructions_instructions_grid_inParams_enabled(): boolean {
      const $editor = this;
      const $utils = this.utils;
      const expr = true;
      
      return expr;
    }
  
    get $tabs_instructions_instructions_grid_inParams_entityKey1(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_tasks_sales_order_tasks_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_tasks_sales_order_tasks_grid_inParams_shipmentId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.ShipmentOrderLookups[0]?.ShipmentId;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_sales_order_lines', { read: FootPrintManager_sales_order_lines_gridComponent }) $tabs_sales_order_lines: FootPrintManager_sales_order_lines_gridComponent;
      @ViewChild('$tabs_picking_tasks', { read: FootPrintManager_sales_order_picking_gridComponent }) $tabs_picking_tasks: FootPrintManager_sales_order_picking_gridComponent;
      @ViewChild('$tabs_manual_allocation_tasks', { read: FootPrintManager_manual_allocations_gridComponent }) $tabs_manual_allocation_tasks: FootPrintManager_manual_allocations_gridComponent;
      @ViewChild('$tabs_shipping_contents', { read: FootPrintManager_shippingcontents_gridComponent }) $tabs_shipping_contents: FootPrintManager_shippingcontents_gridComponent;
        @ViewChild('$tabs_reports_bol_report', { read: SalesOrders_outbound_bill_of_lading_reportComponent }) $tabs_reports_bol_report: SalesOrders_outbound_bill_of_lading_reportComponent;
        @ViewChild('$tabs_reports_pick_slip_report', { read: SalesOrders_pick_slip_by_orderId_reportComponent }) $tabs_reports_pick_slip_report: SalesOrders_pick_slip_by_orderId_reportComponent;
        @ViewChild('$tabs_billing_accessorial_tasks', { read: FootPrintManager_accessorial_tasks_gridComponent }) $tabs_billing_accessorial_tasks: FootPrintManager_accessorial_tasks_gridComponent;
        @ViewChild('$tabs_billing_billing_records', { read: FootPrintManager_billing_records_gridComponent }) $tabs_billing_billing_records: FootPrintManager_billing_records_gridComponent;
      @ViewChild('$tabs_contacts', { read: FootPrintManager_orderaddresses_gridComponent }) $tabs_contacts: FootPrintManager_orderaddresses_gridComponent;
      @ViewChild('$tabs_instructions', { read: FootPrintManager_instructions_gridComponent }) $tabs_instructions: FootPrintManager_instructions_gridComponent;
      @ViewChild('$tabs_tasks', { read: FootPrintManager_sales_order_tasks_gridComponent }) $tabs_tasks: FootPrintManager_sales_order_tasks_gridComponent;
    //#endregion tabs children
    widgets = {
      order_total_units_widget: new WidgetModel(),
      order_total_picked_units_widget: new WidgetModel(),
      order_total_picked_gross_widget: new WidgetModel(),
    };
  
    //#region widgets inParams
    get $widgets_order_total_units_widget_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $widgets_order_total_picked_units_widget_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $widgets_order_total_picked_units_widget_inParams_shipmentId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.vars.shipmentId;
      
      return expr;
    }
  
    get $widgets_order_total_picked_gross_widget_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $widgets_order_total_picked_gross_widget_inParams_shipmentId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.vars.shipmentId;
      
      return expr;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_order_total_units_widget', { read:  SalesOrders_order_total_units_widgetComponent }) $widgets_order_total_units_widget: SalesOrders_order_total_units_widgetComponent;
      @ViewChild('$widgets_order_total_picked_units_widget', { read:  SalesOrders_order_total_picked_units_widgetComponent }) $widgets_order_total_picked_units_widget: SalesOrders_order_total_picked_units_widgetComponent;
      @ViewChild('$widgets_order_total_picked_gross_widget', { read:  SalesOrders_order_total_picked_gross_widgetComponent }) $widgets_order_total_picked_gross_widget: SalesOrders_order_total_picked_gross_widgetComponent;
    //#endregion widgets children

  //#region fields inParams
  get $fields_owner_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_owner_selector_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.project.control.value;
    
    return expr;
  }

  get $fields_project_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_project_selector_inParams_ownerId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.owner.control.value;
    
    return expr;
  }

  get $fields_carrier_selector_inParams_ownerId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.entity.Project.OwnerId;
    
    return expr;
  }

  get $fields_carrier_service_selector_inParams_carrierId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.carrier.control.value;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    lookupcode: this.fields.lookupcode.control.valueChanges
    ,
    order_class: this.fields.order_class.control.valueChanges
    ,
    owner: this.fields.owner.control.valueChanges
    ,
    project: this.fields.project.control.valueChanges
    ,
    warehouse: this.fields.warehouse.control.valueChanges
    ,
    vendor_reference: this.fields.vendor_reference.control.valueChanges
    ,
    expected_date: this.fields.expected_date.control.valueChanges
    ,
    carrier: this.fields.carrier.control.valueChanges
    ,
    carrier_service: this.fields.carrier_service.control.valueChanges
    ,
    reference: this.fields.reference.control.valueChanges
    ,
    container_identifier: this.fields.container_identifier.control.valueChanges
    ,
    bill_of_lading: this.fields.bill_of_lading.control.valueChanges
    ,
    trailer: this.fields.trailer.control.valueChanges
    ,
    seal: this.fields.seal.control.valueChanges
    ,
    tracking: this.fields.tracking.control.valueChanges
    ,
    pickup_date: this.fields.pickup_date.control.valueChanges
    ,
    notes: this.fields.notes.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.sales_order_lines,
      this.tabs.picking_tasks,
      this.tabs.manual_allocation_tasks,
      this.tabs.shipping_contents,
      this.tabs.reports,
      this.tabs.billing,
      this.tabs.contacts,
      this.tabs.instructions,
      this.tabs.tasks,
    ]; 
    this.subTabGroups.reports.tabs = [
        this.tabs.reports.tabs.bol_report,
        this.tabs.reports.tabs.pick_slip_report,
    ];    
    this.subTabGroups.billing.tabs = [
        this.tabs.billing.tabs.accessorial_tasks,
        this.tabs.billing.tabs.billing_records,
    ];    
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.orderId)) {
        this.$missingRequiredInParams.push('orderId');
      }
  }

  initialized = false;
  $hasDataLoaded = false;

  async $init() {
    this.title = 'Edit Sales Order';
    
    await this.on_init();
    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      orderId:  $editor.inParams.orderId 
    };

    const data = await this.datasources.SalesOrders.ds_sales_order_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.lookupcode.control as TextBoxModel).reset($editor.entity.LookupCode);
    (this.fields.order_class.control as SelectBoxModel).reset($editor.entity.OrderClassId);
    (this.fields.owner.control as SelectBoxModel).reset($editor.entity.Project.OwnerId);
    (this.fields.project.control as SelectBoxModel).reset($editor.entity.ProjectId);
    (this.fields.warehouse.control as SelectBoxModel).reset($editor.entity.PreferredWarehouseId);
    (this.fields.order_status.control as TextModel).text = $editor.entity.Status.Name;
    (this.fields.vendor_reference.control as TextBoxModel).reset($editor.entity.VendorReference);
    (this.fields.expected_date.control as DateBoxModel).reset($editor.entity.RequestedDeliveryDate);
    (this.fields.carrier.control as SelectBoxModel).reset($editor.entity.PreferredCarrierId);
    (this.fields.carrier_service.control as SelectBoxModel).reset($editor.entity.PreferredCarrierServiceTypeId);
    (this.fields.reference.control as TextBoxModel).reset($editor.entity.OwnerReference);
    (this.fields.container_identifier.control as TextBoxModel).reset($editor.entity.ShipmentOrderLookups[0]?.Shipment?.ContainerIdentifier);
    (this.fields.bill_of_lading.control as TextBoxModel).reset($editor.entity.ShipmentOrderLookups[0]?.Shipment?.BillOfLading);
    (this.fields.trailer.control as TextBoxModel).reset($editor.entity.ShipmentOrderLookups[0]?.Shipment?.TrailerId);
    (this.fields.seal.control as TextBoxModel).reset($editor.entity.ShipmentOrderLookups[0]?.Shipment?.SealId);
    (this.fields.tracking.control as TextBoxModel).reset($editor.entity.ShipmentOrderLookups[0]?.Shipment?.TrackingIdentifier);
    (this.fields.pickup_date.control as DateBoxModel).reset($editor.entity.ShipmentOrderLookups[0]?.Shipment?.PickupDate?.toString());
    (this.fields.notes.control as TextBoxModel).reset($editor.entity.Notes);

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_order_total_units_widget') {
      if (!isNil(this.$widgets_order_total_units_widget) && !this.widgets.order_total_units_widget.hidden) {
        this.$widgets_order_total_units_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_order_total_picked_units_widget') {
      if (!isNil(this.$widgets_order_total_picked_units_widget) && !this.widgets.order_total_picked_units_widget.hidden) {
        this.$widgets_order_total_picked_units_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_order_total_picked_gross_widget') {
      if (!isNil(this.$widgets_order_total_picked_gross_widget) && !this.widgets.order_total_picked_gross_widget.hidden) {
        this.$widgets_order_total_picked_gross_widget.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
      if (childToSkip !== '$tabs_sales_order_lines') {
        if (!isNil(this.$tabs_sales_order_lines) && !this.tabs.sales_order_lines.hidden) {
          this.$tabs_sales_order_lines.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_picking_tasks') {
        if (!isNil(this.$tabs_picking_tasks) && !this.tabs.picking_tasks.hidden) {
          this.$tabs_picking_tasks.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_manual_allocation_tasks') {
        if (!isNil(this.$tabs_manual_allocation_tasks) && !this.tabs.manual_allocation_tasks.hidden) {
          this.$tabs_manual_allocation_tasks.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_shipping_contents') {
        if (!isNil(this.$tabs_shipping_contents) && !this.tabs.shipping_contents.hidden) {
          this.$tabs_shipping_contents.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_reports_bol_report') {
        if (!isNil(this.$tabs_reports_bol_report) && !this.tabs.reports.tabs.bol_report.hidden) {
          this.$tabs_reports_bol_report.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_reports_pick_slip_report') {
        if (!isNil(this.$tabs_reports_pick_slip_report) && !this.tabs.reports.tabs.pick_slip_report.hidden) {
          this.$tabs_reports_pick_slip_report.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_billing_accessorial_tasks') {
        if (!isNil(this.$tabs_billing_accessorial_tasks) && !this.tabs.billing.tabs.accessorial_tasks.hidden) {
          this.$tabs_billing_accessorial_tasks.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_billing_billing_records') {
        if (!isNil(this.$tabs_billing_billing_records) && !this.tabs.billing.tabs.billing_records.hidden) {
          this.$tabs_billing_billing_records.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_contacts') {
        if (!isNil(this.$tabs_contacts) && !this.tabs.contacts.hidden) {
          this.$tabs_contacts.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_instructions') {
        if (!isNil(this.$tabs_instructions) && !this.tabs.instructions.hidden) {
          this.$tabs_instructions.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_tasks') {
        if (!isNil(this.$tabs_tasks) && !this.tabs.tasks.hidden) {
          this.$tabs_tasks.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .lookupcode
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .order_class
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .owner
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_changed();
      });
    this.$formGroupFieldValidationObservables
      .project
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .warehouse
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .vendor_reference
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .expected_date
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .carrier
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .carrier_service
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .reference
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .container_identifier
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .bill_of_lading
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .trailer
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .seal
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .tracking
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .pickup_date
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
  }

  //#region private flows
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $editor: FootPrintManager_sales_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const result = await $flows.SalesOrders.is_sales_order_deletable_flow({ orderId: $editor.entity.Id });
  const reason = result.reason;
  if ($utils.isDefined(reason)) {
      await $shell.FootPrintManager.openErrorDialog('Order cannot be deleted.', reason);
      return;
  } else {
      const confirm = await $shell.FootPrintManager.openConfirmationDialog('Delete Order', `Are you sure you want to delete order  ${$editor.entity.LookupCode}?`, 'Yes', 'No');
      if (confirm) {
          try {
              const orderLookup = $editor.entity.LookupCode;
              const result = await $flows.SalesOrders.delete_sales_order_flow({ orderId: $editor.entity.Id });
              const reason = result.reason;
  
              if ($utils.isDefined(reason)) {
                  await $shell.FootPrintManager.openErrorDialog('Order cannot be deleted.', reason);
                  return;
              }
              else {
                  const title = `Order ${orderLookup} deleted`
                  $shell.FootPrintManager.openToaster(title,'',EToasterType.Success,{timeOut: 5000, positionClass:EToasterPosition.topRight});
                  $editor.close();
              }
          } catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.FootPrintManager.openErrorDialog('Order delete error', 'An error occurred during the deletion of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: FootPrintManager_sales_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  var shipmentId = $editor.entity.ShipmentOrderLookups[0]?.ShipmentId;
  var orderId = $editor.inParams.orderId;
  var orderStatusId = $editor.entity.OrderStatusId;
  
  if ($utils.isDefined(shipmentId)) {
  
      const result = await $flows.SalesOrders.is_sales_order_cancelable_flow({ 
          orderId: $editor.entity.Id, 
          shipmentId: $editor.entity.ShipmentOrderLookups[0]?.ShipmentId });
      const reason = result.reason;
      if ($utils.isDefined(reason)) {
          await $shell.FootPrintManager.openErrorDialog('Order cannot be canceled.', reason);
          return;
      } else {
          const confirm = await $shell.FootPrintManager.openConfirmationDialog('Cancel order', `Are you sure you want to cancel order ${$editor.entity.LookupCode}?`, 'Yes', 'No');
          if (confirm) {
              try {
  
                  if (orderStatusId === 1) {
  
                      const result = await $flows.SalesOrders.cancel_sales_order_flow({
                          orderId: orderId
                      });
                      const reason = result.reason;
  
                      if ($utils.isDefined(reason)) {
                          await $shell.FootPrintManager.openErrorDialog('Order cannot be cancelled.', reason);
                          return;
                      }
                      else {
  
                              const title = `Order ${$editor.entity.LookupCode} cancelled`;
                              $shell.FootPrintManager.openToaster(title, '', EToasterType.Success, { timeOut: 5000, positionClass: EToasterPosition.topRight });
                              await $editor.refresh();
                              await $editor.tabs.sales_order_lines.activate();
                      }
  
                  }
                  else {
                      const dialogResult = await $shell.FootPrintManager.opensales_order_cancellation_options_formDialog({
                          title: 'Select a reason code',
                          parentId: 45,
                          parentEntity: 'OperationType'
                      });
                      var userConfirmed = dialogResult.confirm;
                      var reasonCodeId = dialogResult.reasonCodeId;
  
                      if ($utils.isDefined(userConfirmed) && userConfirmed === false) {
  
                          return;
                      }
  
                      if (userConfirmed) {
  
                          const result = await $flows.SalesOrders.cancel_sales_order_shipment_and_order_lines_flow({
                              shipmentId: shipmentId,
                              reasonCodeId: reasonCodeId
                          });
                          const reason = result.reason;
  
                          if ($utils.isDefined(reason)) {
                              await $shell.FootPrintManager.openErrorDialog('Order cannot be cancelled.', reason);
                              return;
                          }
                          else {
                              const title = `Order ${$editor.entity.LookupCode} cancelled`;
                              $shell.FootPrintManager.openToaster(title, '', EToasterType.Success, { timeOut: 5000, positionClass: EToasterPosition.topRight });
                              await $editor.refresh();
                              await $editor.tabs.sales_order_lines.activate();
                          }
  
                      }
  
                  }
  
              } catch (error) {
                  const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                  const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                  const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
                  await $shell.FootPrintManager.openErrorDialog('Cancel order error', 'An error occurred during the cancelling of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
              }
          }
      }
  
  }
  else {
  
      throw new Error('Unable to determine the shipment for the selected order.')
  }
  }
  on_complete_clicked(event = null) {
    return this.on_complete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_complete_clickedInternal(
    $editor: FootPrintManager_sales_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  const result = await $flows.SalesOrders.is_sales_order_completable_flow({ orderId: $editor.entity.Id });
  const reason = result.reason;
  if ($utils.isDefined(reason)) {
      await $shell.FootPrintManager.openErrorDialog('Order cannot be completed.', reason);
      return;
  } else {
      const confirm = await $shell.FootPrintManager.openConfirmationDialog('Complete Order', `Are you sure you want to complete order  ${$editor.entity.LookupCode}?`, 'Yes', 'No');
      if (confirm) {
          try {
              const result = await $flows.SalesOrders.complete_sales_order_flow({ orderId: $editor.entity.Id });
              const reason = result.reason;
  
              if ($utils.isDefined(reason)) {
                  await $shell.FootPrintManager.openErrorDialog('Order cannot be completed.', reason);
                  return;
              }
              else {
  
                  let title = `Order ${$editor.entity.LookupCode} completed`;
                  $shell.FootPrintManager.openToaster(title,'',EToasterType.Success,{timeOut: 5000, positionClass:EToasterPosition.topRight});
                  await $editor.refresh();
                  await $editor.tabs.shipping_contents.activate();
              }
  
          } catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.FootPrintManager.openErrorDialog('Order complete error', 'An error occurred during the completion of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootPrintManager_sales_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/12/2024
  
  // Set editor title
  $editor.title = `Outbound Order ${$editor.entity.LookupCode}`;
  
  var shipTo = '';
  
  const orderAddress = (await $flows.Addresses.get_address_single_string_by_orderId({ orderId: $editor.inParams.orderId }));
  if ($utils.isDefined(orderAddress)) {
      shipTo = orderAddress.address;
  
  }
  // Only display ship from if it is not an auto generated account
  if ($utils.isDefined(shipTo)) {
      if (!shipTo.startsWith('ord-')) {
  
          $editor.fields.ship_to.hidden = false;
          $editor.fields.ship_to.control.text = shipTo;
          $editor.fields.ship_to_clear.hidden = false;
  
      } else {
          $editor.fields.ship_to.hidden = true;
          $editor.fields.ship_to.control.text = '';
          $editor.fields.ship_to_clear.hidden = true;
      }
  }
  else {
      $editor.fields.ship_to.hidden = true;
      $editor.fields.ship_to.control.text = '';
      $editor.fields.ship_to_clear.hidden = true;
  }
  
  // Apply appointment text logic
  const dockAppointments = (await $datasources.DockAppointments.ds_get_dock_appointment_by_orderId.get({
      orderId: $editor.inParams.orderId
  })).result;
  
  if ($utils.isDefined(dockAppointments)) {
      $editor.toolbar.appointment.control.label = (await $flows.DockAppointments.get_formatted_dock_appointment({
          dockAppointmentId: dockAppointments[0]?.DockAppointmentId,
      })).formattedDockAppointment;
  
  } else {
      // Reset the appointment label back to original
      $editor.toolbar.appointment.control.styles.resetStyle();
      $editor.toolbar.appointment.control.label = 'Appointment'
  }
  
  // Discussions feature
  let discussionCheck = (await $operations.Discussions.Enable_Discussions_Feature.isAuthorized())
  
  if (discussionCheck) {
      const discussions = (await $datasources.Discussions.ds_storage_discussions.getList({
          entity_name: 'Order',
          entity_id: $editor.inParams.orderId.toString()
      })).result;
  
      if ($utils.isDefined(discussions)) {
  
          let containsUnread = false;
          let containsDiscussion = false;
  
          for (let discussion of discussions) {
              let read_on = discussion.read_on
              let discussionData = discussion.discussion
  
              if (!$utils.isDefined(read_on) && discussion.id != '0') {
                  containsUnread = true;
              }
              if ($utils.isDefined(discussionData) && discussion.id != '0') {
                  containsDiscussion = true;
              }
          }
          if (containsDiscussion) {
              if (containsUnread) {
                  $editor.toolbar.options.control.buttons.discussions.icon = "icon ms-Icon ms-Icon--OfficeChat";
                  $editor.toolbar.options.control.buttons.discussions.styles.setDestructiveClass();
              }
              else {
                  $editor.toolbar.options.control.buttons.discussions.icon = "icon ms-Icon ms-Icon--CannedChat";
                  $editor.toolbar.options.control.buttons.discussions.styles.setDestructiveClass();
              }
          }
          else {
  
              $editor.toolbar.options.control.buttons.discussions.icon = "icon ms-Icon ms-Icon--Chat";
              $editor.toolbar.options.control.buttons.discussions.styles.setCreationClass();
  
          }
      }
  }
  else {
      $editor.toolbar.options.control.buttons.discussions.readOnly = true;
  }
  
  
  // Fetch shipments
  const shipments = (await $datasources.SalesOrders.ds_get_shipments_by_orderId.get({ orderId: $editor.entity.Id })).result;
  
  //reprocess new orderlines feature
  const created_lines = (await $datasources.SalesOrders.ds_get_orderlines_by_orderId_linestatusids.get({orderId: $editor.inParams.orderId, order_status_ids: [2], line_status_ids: [1] })).totalCount
  if (created_lines>0 )
  {$editor.toolbar.process.control.label = 'Process new order lines'}
  
  
  
  if (shipments.length > 1) {
      // close
      await $shell.SalesOrders.openErrorDialog('Invalid order', 'This order has more than one shipment. This editor cannot display this kind of order. Editor will close');
      $editor.close();
      return;
  }
  
  else {
  
      $editor.fields.warehouse.control.readOnly = false;
      $editor.fields.warehouse.required = false;
  
      if (shipments.length === 1) {
          $editor.fields.container_identifier.control.readOnly = false;
          $editor.vars.shipmentId = shipments[0].ShipmentId;
          $editor.fields.warehouse.required = true;
      }
  
      const noWarehouse = $utils.isDefined($editor.fields.warehouse.control.value) === false;
  
      let stateId: number = 1;
      let has_manual: boolean = false;
      let stateName: string = $editor.entity.Status.Name;
  
      const orders = [{
          orderId: $editor.entity.Id,
          shipmentId: $editor.entity.ShipmentOrderLookups[0]?.ShipmentId
      }];
  
      const order_states = (await $flows.SalesOrders.get_sales_order_state({ orders: orders })).states;
  
      if (order_states.length > 0) {
          stateId = order_states[0].stateId;
          stateName = order_states[0].stateName;
          has_manual = order_states[0].has_manual;
      };
  
      $editor.vars.orderState = stateId;
      $editor.fields.order_state.control.text = stateName;
  
      // created status
      if ($editor.entity.OrderStatusId === 1) {
          $editor.toolbar.cancel.hidden = false;
          $editor.toolbar.on_delete.hidden = false;
          $editor.toolbar.on_delete.control.readOnly = false;
  
          // Reset process button in case the editor is refreshed back to a created status
          $editor.toolbar.process.hidden = false;
          $editor.toolbar.process.control.styles.resetStyle();
          $editor.toolbar.process.control.label = 'Process';
          $editor.toolbar.process.control.readOnly = false;
          $editor.toolbar.process.control.icon = "icon ms-Icon ms-Icon--Process";
  
          // disable process if no warehouse
          $editor.toolbar.process.control.readOnly = noWarehouse;
  
          $editor.toolbar.auto_load.hidden = true;
          $editor.toolbar.auto_pick.hidden = true;
          $editor.toolbar.complete.hidden = true;
          $editor.toolbar.revert.hidden = true;
  
          // disable owner project if order lines exist
          const hasOrderLines = $editor.entity.OrderLines.length > 0;
          $editor.fields.owner.control.readOnly = hasOrderLines;
          $editor.fields.project.control.readOnly = hasOrderLines;
  
          $editor.fields.lookupcode.control.readOnly = false;
          $editor.fields.order_class.control.readOnly = false;
          $editor.fields.vendor_reference.control.readOnly = false;
          $editor.fields.carrier.control.readOnly = false;
          $editor.fields.expected_date.control.readOnly = false;
  
          // Widgets
          $editor.widgets.order_total_picked_gross_widget.hidden = true;
          $editor.widgets.order_total_picked_units_widget.hidden = true;
  
          if ($editor.tabs.picking_tasks.active) {
              $editor.tabs.sales_order_lines.activate();
          }
  
          $editor.tabs.picking_tasks.hidden = true;
          $editor.tabs.manual_allocation_tasks.hidden = true;
          $editor.tabs.shipping_contents.hidden = true;
          $editor.tabs.tasks.hidden = true;
          $editor.tabs.billing.hidden = true;
          $editor.toolbar.options.control.buttons.temperature_readings.readOnly = true;
  
  
      } else if ($editor.entity.OrderStatusId === 2) {
          // processing status
  
          $editor.fields.lookupcode.control.readOnly = false;
          $editor.fields.order_class.control.readOnly = true;
          $editor.fields.owner.control.readOnly = true;
          $editor.fields.project.control.readOnly = true;
          $editor.fields.warehouse.control.readOnly = true;
          $editor.fields.vendor_reference.control.readOnly = false;
          $editor.fields.carrier.control.readOnly = false;
          $editor.fields.expected_date.control.readOnly = false;
  
          $editor.tabs.tasks.hidden = false;
          $editor.tabs.billing.hidden = false;
          $editor.toolbar.options.control.buttons.temperature_readings.readOnly = false;
  
          const shipmentContents = (await $datasources.SalesOrders.ds_get_shipmentscontents_by_orderId_shipmentId_top1.get({
              orderId: $editor.inParams.orderId,
              shipmentId: $editor.vars.shipmentId
          })).result;
  
          // Revert button and Shipping Contents 'Picked' tab
          if ($utils.isDefined(shipmentContents)) {
              $editor.toolbar.revert.hidden = false;
              $editor.toolbar.revert.control.readOnly = true;
  
              $editor.tabs.shipping_contents.hidden = false;
              //  $editor.tabs.reports.tabs.bol_report.hidden = false;
          } else {
              $editor.toolbar.revert.hidden = false;
              $editor.toolbar.revert.control.readOnly = false;
  
              $editor.tabs.shipping_contents.hidden = true;
              //  $editor.tabs.reports.tabs.bol_report.hidden = true;
          }
  
          // Set manual allocations
          var manualAllocations;
          if ($utils.isDefined(has_manual)) {
  
              if (has_manual) {
                  $editor.tabs.manual_allocation_tasks.hidden = false;
                  manualAllocations = true;
              } else {
                  manualAllocations = false;
                  $editor.tabs.manual_allocation_tasks.hidden = true;
              }
          } else {
              manualAllocations = false;
              $editor.tabs.manual_allocation_tasks.hidden = true;
          }
  
          if ($utils.isDefined(stateId)) {
              switch (stateId) {
  
                  case 2: {
                      $editor.toolbar.cancel.hidden = false;
                      $editor.toolbar.cancel.control.readOnly = false;
  
                      $editor.toolbar.process.hidden = false;
                      $editor.toolbar.process.control.readOnly = false;
  
                      $editor.toolbar.on_delete.hidden = true;
                      $editor.toolbar.auto_load.hidden = true;
                      $editor.toolbar.auto_pick.hidden = true;
                      $editor.toolbar.complete.hidden = true;
  
                      $editor.tabs.picking_tasks.hidden = true;
  
                      break;
                  }
                  case 3: {
                      $editor.toolbar.cancel.hidden = false;
                      $editor.toolbar.cancel.control.readOnly = false;
  
                      $editor.toolbar.process.hidden = false;
                      $editor.toolbar.process.control.readOnly = false;
  
                      $editor.toolbar.on_delete.hidden = true;
                      $editor.toolbar.auto_load.hidden = true;
                      $editor.toolbar.auto_pick.hidden = true;
                      $editor.toolbar.complete.hidden = true;
  
                      $editor.tabs.picking_tasks.hidden = true;
  
                      break;
                  }
                  case 4: {
                      $editor.toolbar.cancel.hidden = false;
                      $editor.toolbar.cancel.control.readOnly = false;
  
                      $editor.toolbar.process.hidden = false;
                      $editor.toolbar.process.control.readOnly = false;
  
                      $editor.toolbar.on_delete.control.readOnly = true;
                      $editor.toolbar.auto_load.hidden = true;
                      $editor.toolbar.auto_pick.hidden = true;
                      $editor.toolbar.complete.hidden = true;
  
                      $editor.tabs.picking_tasks.hidden = true;
  
                      // $editor.tabs.reports.tabs.pick_slip_report.hidden = false;
  
                      break;
                  }
                  // All pick tasks in a released status
                  case 5: {
                      $editor.toolbar.cancel.hidden = false;
                      $editor.toolbar.cancel.control.readOnly = false;
                      $editor.toolbar.auto_pick.hidden = false;
  
                      $editor.toolbar.on_delete.control.readOnly = true;
  
                      if (created_lines>0)
                      {$editor.toolbar.process.hidden = false;}
                      else
                      {$editor.toolbar.process.hidden = true;}
                      
                      $editor.toolbar.auto_load.hidden = true;
                      $editor.toolbar.complete.hidden = true;
  
                      $editor.tabs.picking_tasks.hidden = false;
  
                      //  $editor.tabs.reports.tabs.pick_slip_report.hidden = false;
  
                      break;
                  } // One or more pick tasks completed and one or more pick tasks released
                  case 6: {
                      $editor.toolbar.auto_pick.hidden = false;
                      $editor.toolbar.auto_load.hidden = false;
                      $editor.toolbar.cancel.control.readOnly = true;
                      $editor.toolbar.on_delete.control.readOnly = true;
  
                      if (created_lines>0)
                      {$editor.toolbar.process.hidden = false;}
                      else
                      {$editor.toolbar.process.hidden = true;}
  
                      $editor.toolbar.complete.hidden = true;
                      $editor.tabs.picking_tasks.hidden = false;
  
  
                      break;
                  } // No released pick tasks exist and one or more shipping licenseplates require a loading task
                  case 7: {
                      $editor.toolbar.auto_load.hidden = false;
  
                      $editor.toolbar.cancel.control.readOnly = true;
                      $editor.toolbar.on_delete.control.readOnly = true;
                      if (created_lines>0)
  
                      {$editor.toolbar.process.hidden = false;}
                      else
                      {$editor.toolbar.process.hidden = true;}
  
                      $editor.toolbar.auto_pick.hidden = true;
                      $editor.toolbar.complete.hidden = true;
  
                      $editor.tabs.picking_tasks.hidden = true;
  
                      //   $editor.tabs.reports.tabs.pick_slip_report.hidden = true;
  
                      break;
                  } // No released pick tasks or manual allocations exist and all shipping licenseplates loaded
                  case 8: {
                      if (manualAllocations) {
                          $editor.toolbar.complete.hidden = false;
                          $editor.toolbar.complete.control.readOnly = true;
                      } else {
                          $editor.toolbar.complete.hidden = false;
                          $editor.toolbar.complete.control.readOnly = false;
  
                          //  $editor.tabs.reports.tabs.pick_slip_report.hidden = true;
                      }
  
                      $editor.toolbar.cancel.control.readOnly = true;
                      $editor.toolbar.on_delete.control.readOnly = true;
  
                      if (created_lines>0)
                      {$editor.toolbar.process.hidden = false;}
                      else
                      {$editor.toolbar.process.hidden = true;}
  
                      $editor.toolbar.auto_pick.hidden = true;
                      $editor.toolbar.auto_load.hidden = true;
  
                      $editor.tabs.picking_tasks.hidden = true;
  
                      break;
                  }
                  // No released pick tasks or manual allocations exist and all shipping contents unpicked
                  case 9: {
                      if (manualAllocations) {
                          $editor.toolbar.complete.hidden = false;
                          $editor.toolbar.complete.control.readOnly = true;
                          $editor.toolbar.cancel.hidden = false;
                          $editor.toolbar.cancel.control.readOnly = true;
                      } else {
                          $editor.toolbar.complete.hidden = false;
                          $editor.toolbar.complete.control.readOnly = true;
                          $editor.toolbar.cancel.hidden = false;
                          $editor.toolbar.cancel.control.readOnly = false;
                      }
  
  
                      $editor.toolbar.on_delete.control.readOnly = true;
  
                      if (created_lines>0)
                      {$editor.toolbar.process.hidden = false;}
                      else
                      {$editor.toolbar.process.hidden = true;}
  
                      $editor.toolbar.auto_pick.hidden = true;
                      $editor.toolbar.auto_load.hidden = true;
                      $editor.tabs.picking_tasks.hidden = true;
  
                      break;
                  }
              }
          } else {
              if (manualAllocations) {
                  $editor.fields.order_status.control.text = 'Manual Allocations'
                  $editor.toolbar.complete.hidden = false;
                  $editor.toolbar.complete.control.readOnly = true;
              }
  
              if (created_lines>0)
              {$editor.toolbar.process.hidden = false;}
              else
              {$editor.toolbar.process.hidden = true;}
              
              //$editor.toolbar.process.hidden = true;        
              $editor.toolbar.auto_load.hidden = true;
              $editor.toolbar.auto_pick.hidden = true;
  
              $editor.tabs.reports.tabs.pick_slip_report.hidden = true;
  
          }
          // Widgets
          $editor.widgets.order_total_picked_gross_widget.hidden = false;
          $editor.widgets.order_total_picked_units_widget.hidden = false;
  
  
      } else {
  
          if (created_lines>0)
          {$editor.toolbar.process.hidden = false;}
          else
          {$editor.toolbar.process.hidden = true;}
  
          //$editor.toolbar.process.hidden = true;            
          $editor.toolbar.cancel.hidden = true;
          $editor.toolbar.complete.hidden = true;
          $editor.toolbar.on_delete.hidden = true;
          $editor.toolbar.auto_load.hidden = true;
          $editor.toolbar.auto_pick.hidden = true;
          $editor.toolbar.revert.hidden = true;
  
          $editor.fields.lookupcode.control.readOnly = true;
          $editor.fields.order_class.control.readOnly = true;
          $editor.fields.owner.control.readOnly = true;
          $editor.fields.project.control.readOnly = true;
          $editor.fields.vendor_reference.control.readOnly = true;
          $editor.fields.carrier.control.readOnly = true;
          $editor.fields.warehouse.control.readOnly = true;
          $editor.fields.expected_date.control.readOnly = true;
  
          // Widgets
          $editor.widgets.order_total_picked_gross_widget.hidden = false;
          $editor.widgets.order_total_picked_units_widget.hidden = false;
  
          $editor.tabs.picking_tasks.hidden = true;
  
          $editor.tabs.shipping_contents.hidden = false;
          // $editor.tabs.reports.tabs.bol_report.hidden = false;
          //  $editor.tabs.reports.tabs.pick_slip_report.hidden = true;
  
          $editor.tabs.tasks.hidden = false;
          $editor.tabs.billing.hidden = false;
          $editor.toolbar.options.control.buttons.temperature_readings.readOnly = false;
  
      }
  }
  
  
  /*
  //reprocess new orderlines feature
  const shipment_info = (await $datasources.SalesOrders.ds_get_shipments_by_shipmentId.get({ shipmentId: shipments[0].ShipmentId })).result;
  const created_lines = (await $datasources.SalesOrders.ds_get_orderlines_by_orderId_linestatusids.get({orderId: $editor.inParams.orderId, order_status_id: 2, line_status_ids: [1] })).totalCount
  if (created_lines>0 && $utils.isDefined(shipment_info.WaveId))
  {$editor.toolbar.process_new_order_lines.hidden = false}
  else {{$editor.toolbar.process_new_order_lines.hidden = true}}
  */
  
  
  $editor.apply_operations();
  
  //Integration information
  
  let integration_info = (await $flows.FootPrintManager.get_integration_info_by_order_id({ order_id: $editor.inParams.orderId }));
  
  if ($utils.isDefined(integration_info?.Source)) {
      $editor.fields.integration_information.hidden = false;
      $editor.fields.integration_information.control.label = integration_info.Source;
  }
  $editor.fields.pickup_date.control.readOnly = false;
  $editor.fields.pickup_date.control.format =
      `${$settings.FootPrintManager.DateFormat}, ${$settings.FootPrintManager.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootPrintManager_sales_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.tabs.picking_tasks.hidden = true;
  $editor.tabs.manual_allocation_tasks.hidden = true;
  $editor.tabs.shipping_contents.hidden = true;
  $editor.fields.integration_information.hidden = true;
  
  if (await $operations.FootPrintManager.Disable_SalesOrders_AutoPicking.isAuthorized()) {
      $editor.toolbar.auto_pick.hidden = true;
  }
  
  // Call common flow to apply the role based permissions
  await $editor.apply_operations();
  
  // Set Date/Time Formats
  $editor.fields.expected_date.control.format = `${$settings.FootPrintManager.DateFormat}, ${$settings.FootPrintManager.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  // Create shipment if none found
  const shipments = (await $datasources.SalesOrders.ds_get_shipments_by_orderId.get({ orderId: $editor.inParams.orderId })).result;
  if (!$utils.isDefined(shipments)) {
  
      const nextShipmentId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Shipment' })).nextId;
  
      const order = (await $datasources.SalesOrders.ds_get_order_by_orderId.get({ orderId: $editor.inParams.orderId })).result;
  
      if ($utils.isAllDefined(order, order.PreferredWarehouseId)) {
  
          // create shipment order lookup
          const payload = {
              "OrderId": $editor.inParams.orderId,
              "Shipment": {
                  "Id": nextShipmentId,
                  "LookupCode": order.LookupCode,
                  "CarrierId": order.PreferredCarrierId,
                  "CarrierServiceTypeId": order.PreferredCarrierServiceTypeId,
                  "ExpectedWarehouseId": order.PreferredWarehouseId,
                  "ExpectedDate": order.RequestedDeliveryDate,
                  "Cartonized": false,
                  "ConfirmSeal": false,
                  "StatusId": 1,
                  "Transhipment": false,
                  "TypeId": 2
              }
          }
          await $flows.Utilities.crud_create_flow({ entitySet: 'ShipmentOrderLookup', entity: payload });
          $editor.vars.shipmentId = nextShipmentId;
      }
  }
  
  // Set warehouse on order if blank and shipment contains expected warehouse
  const orders = (await $datasources.SalesOrders.ds_get_order_by_orderId.get({ orderId: $editor.inParams.orderId })).result;
  if (!$utils.isDefined(orders.PreferredWarehouseId) && $utils.isDefined(shipments[0]?.Shipment?.ExpectedWarehouseId)) {
  
      let payload: any = {};
  
      payload.PreferredWarehouseId = shipments[0]?.Shipment?.ExpectedWarehouseId;
  
      await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.inParams.orderId, entity: payload });
  }
  
  //reprocess new orderlines feature
  var reprocess: boolean = false
  const created_lines = (await $datasources.SalesOrders.ds_get_orderlines_by_orderId_linestatusids.get({ orderId: $editor.inParams.orderId, order_status_ids: [2], line_status_ids: [1] })).totalCount
  //if (created_lines>0 && $utils.isDefined(shipment_info.WaveId))
  if (created_lines > 0) {$editor.toolbar.process.control.label = 'Process new order lines'}
  
  }
  on_owner_changed(event = null) {
    return this.on_owner_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changedInternal(
    $editor: FootPrintManager_sales_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.fields.project.control.value = null;
  }
  on_process_clicked(event = null) {
    return this.on_process_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_process_clickedInternal(
    $editor: FootPrintManager_sales_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  var section = 'order processing';
  
  try {
      const orderlines = (await $datasources.SalesOrders.ds_get_orderlines_by_orderId_without_children.get({ orderId: $editor.entity.Id })).result;
      if (!$utils.isDefined(orderlines)) {
          throw new Error('Order must have at least one order line to process.')
      }
  
      // Order Substitution Flow Execution
      section = 'order substitution';
      const sub = await $flows.SalesOrders.substitution_sales_order_flow({ orderId: $editor.entity.Id });
      if ($utils.isDefined(sub.reason)) {
          throw new Error(sub.reason);
      }
  
      section = 'order processing';
      const isProcessable = await $flows.SalesOrders.is_sales_order_processable_flow({ orderId: $editor.entity.Id });
  
      if ($utils.isDefined(isProcessable.reason)) {
          throw new Error(isProcessable.reason);
      }
  
      // Check if the order contains at least one order line that can be expanded into children kit components
      let expand_kits = false;
      const kits = (await $datasources.SalesOrders.ds_get_material_kit_order_line_by_order_id_top1.get({ order_id: $editor.entity.Id })).result;
      if ($utils.isDefined(kits)) {
          expand_kits = true;
      }
  
      //reprocess new orderlines feature
      var reprocess: boolean = false
      var is_wave_created: boolean = false
      const shipment_info = (await $datasources.SalesOrders.ds_get_shipments_by_shipmentId.get({ shipmentId: $editor.entity.ShipmentOrderLookups[0].ShipmentId })).result;
      const created_lines = (await $datasources.SalesOrders.ds_get_orderlines_by_orderId_linestatusids.get({ orderId: $editor.inParams.orderId, order_status_ids: [2], line_status_ids: [1] })).totalCount
      //if (created_lines>0 && $utils.isDefined(shipment_info.WaveId))
      if (created_lines > 0) { reprocess = true }
      if ($utils.isDefined(shipment_info.WaveId)) { is_wave_created = true }
  
  
  
      /*** Prompt for processing sales order options ***/
      const dialogResult = await $shell.FootPrintManager.opensales_orders_processing_options_formDialog({
          isMultiShipment: false,
          waveOptionDefaultCreate: true,
          expand_order_line_kits: expand_kits,
          is_wave_created: is_wave_created
      }, 'modal');
  
      if (!dialogResult.confirm) { return; }
  
      dialogResult.waveOptions.priority = $utils.isDefined(dialogResult.waveOptions?.priority) ? dialogResult.waveOptions.priority : 1;
      dialogResult.waveOptions.description = $utils.isDefined(dialogResult.waveOptions?.description) ? dialogResult.waveOptions.description : `Created via processing flow`
      dialogResult.waveOptions.isCreateManualAllocationTasks = dialogResult.waveOptions.isCreateManualAllocationTasks ?? false;
  
  
  
      let targetState = 2 // process order
      if (dialogResult.waveOptions.isCreateWave) { targetState = 3; }
      if (dialogResult.waveOptions.isProcessWave) { targetState = 4; }
      if (dialogResult.waveOptions.isReleaseWave) { targetState = 5; }
  
      let processingStrategyWorkflowCode = dialogResult.processingStrategyWorkflowId;
      let expand_kits_request = dialogResult.expand_order_line_kits_request;
  
  
  
      // Begin processing
      $editor.toolbar.process.control.readOnly = true;
      $editor.toolbar.process.control.icon = "icon ms-Icon ms-Icon--ProgressRingDots";
  
      $editor.toolbar.cancel.control.readOnly = true;
      $editor.toolbar.on_delete.control.readOnly = true;
  
      // Create children kit order lines if required
      if ($utils.isDefined(expand_kits_request) && expand_kits_request === true) {
  
          $editor.toolbar.process.control.label = 'Expanding kits';
          const component_lines = (await $datasources.SalesOrders.ds_get_material_kit_order_lines_by_order_id.get({
              order_id: $editor.inParams.orderId
          })).result;
          if ($utils.isDefined(component_lines)) {
  
              for (let component_line of component_lines) {
  
                  const order_line_packaging_id = component_line.PackagedId;
                  const kits = component_line.Material?.Kits
                  if ($utils.isDefined(kits)) {
                      for (let kit of kits) {
  
                          const kit_packaging = kit.PackagingId
                          //Only create kit components where the packaging on the order line matches the packaging on the configured parent kit material
                          if (kit_packaging == order_line_packaging_id) {
  
                              const orderLine = (await $flows.SalesOrders.create_sales_order_line_flow({
                                  orderId: $editor.inParams.orderId,
                                  packagedAmount: component_line.PackagedAmount * kit.ComponentPackagedAmount,
                                  materialId: kit.ComponentMaterialId,
                                  packagingId: kit.ComponentPackagedId,
                                  shipmentId: $editor.entity.ShipmentOrderLookups[0]?.ShipmentId
                              }));
                              if ($utils.isDefined(orderLine)) {
  
                                  if (!$utils.isDefined(orderLine.linenumber)) {
  
                                      throw new Error('Unable to create order line.')
                                  }
                                  else {
  
                                      let payload: any = {};
  
                                      if ($utils.isDefined(component_line.LineNumber)) {
                                          payload.ParentLineNumber = component_line.LineNumber;
                                      }
  
                                      if (Object.keys(payload).length > 0) {
                                          await $flows.Utilities.crud_update_dual_pk_flow({
                                              entitySet: 'OrderLines',
                                              pk1String: 'OrderId',
                                              pk1: $editor.inParams.orderId,
                                              pk2String: 'LineNumber',
                                              pk2: orderLine.linenumber,
                                              entity: payload
                                          });
                                      }
                                  }
                              }
                              else {
                                  throw new Error('Unable to create order line.')
                              }
  
                          }
                      }
                  }
  
  
              }
  
  
          }
  
  
      }
  
      // Set custom status
      let stateId: number = 1;
  
      const orders = [{
          orderId: $editor.entity.Id,
          shipmentId: $editor.entity.ShipmentOrderLookups[0].ShipmentId
      }];
  
      const order_states = (await $flows.SalesOrders.get_sales_order_state({ orders: orders })).states;
  
      if (order_states.length > 0) {
          stateId = order_states[0].stateId;
      };
  
      if (stateId < targetState) {
  
          if (reprocess) {
              section = 'Reprocess new sales order lines';
              $editor.toolbar.process.control.label = 'Processing'
              let result = await $flows.SalesOrders.reprocess_new_sales_order_lines_by_shipment({
                  order_id: $editor.entity.Id,
                  shipment_id: $editor.entity.ShipmentOrderLookups[0].ShipmentId,
                  processing_strategy_workflow_code: processingStrategyWorkflowCode
              });
  
              if ($utils.isDefined(result.reason)) {
                  throw new Error(result.reason);
              }
          }
  
          for (let i = stateId; i < targetState; i++) {
              switch (i) {
                  case 1: {
                      section = 'order processing';
                      let soft = await $flows.SalesOrders.is_sales_order_soft_allocatable_flow({
                          orderId: $editor.entity.Id
                      });
  
                      $editor.toolbar.process.control.label = 'Processing'
  
                      let result = await $flows.SalesOrders.process_sales_order_flow({
                          order_id: $editor.entity.Id,
                          shipment_id: $editor.entity.ShipmentOrderLookups[0].ShipmentId,
                          processing_strategy_workflow_code: processingStrategyWorkflowCode
                      });
  
                      if ($utils.isDefined(result.reason)) {
                          throw new Error(result.reason);
                      }
  
  
  
                      break;
                  }
                  case 2: {
                      section = 'wave creation';
                      $editor.toolbar.process.control.label = 'Creating Wave'
                      let result = await $flows.SalesOrders.create_wave_sales_order_flow({
                          orderId: $editor.entity.Id,
                          shipmentId: $editor.entity.ShipmentOrderLookups[0].ShipmentId
                      });
  
                      if ($utils.isDefined(result.reason)) {
                          throw new Error(result.reason);
                      }
  
                      break;
                  }
                  case 3: {
                      section = 'wave processing';
                      $editor.toolbar.process.control.label = 'Processing Wave'
                      let result = await $flows.SalesOrders.process_wave_sales_order_flow({
                          orderId: $editor.entity.Id,
                          shipmentId: $editor.entity.ShipmentOrderLookups[0].ShipmentId,
                          is_create_manual_allocation_tasks: dialogResult.waveOptions.isCreateManualAllocationTasks
  
                      });
  
                      if ($utils.isDefined(result.reason)) {
                          throw new Error(result.reason);
                      }
  
                      break;
                  }
                  case 4: {
                      section = 'wave release';
                      $editor.toolbar.process.control.label = 'Releasing Wave'
                      let result = await $flows.SalesOrders.release_wave_sales_order_flow({
                          orderId: $editor.entity.Id,
                          shipmentId: $editor.entity.ShipmentOrderLookups[0].ShipmentId
                      });
  
                      if ($utils.isDefined(result.reason)) {
                          throw new Error(result.reason);
                      }
  
                      break;
                  }
              }
          }
      }
      else {
          if (reprocess) {
              section = 'Reprocess new sales order lines';
              $editor.toolbar.process.control.label = 'Processing'
              let result = await $flows.SalesOrders.reprocess_new_sales_order_lines_by_shipment({
                  order_id: $editor.entity.Id,
                  shipment_id: $editor.entity.ShipmentOrderLookups[0].ShipmentId,
                  processing_strategy_workflow_code: processingStrategyWorkflowCode
              });
  
              if ($utils.isDefined(result.reason)) {
                  throw new Error(result.reason);
              }
          }
      }
  
      section = 'order processing';
      const title = `Order ${$editor.entity.LookupCode} processed`
      $shell.FootPrintManager.openToaster(title, '', EToasterType.Success, { timeOut: 5000, positionClass: EToasterPosition.topRight });
  
      await $editor.tabs.picking_tasks.activate();
  
  
  } catch (error) {
      while ($utils.isDefined(error.error)) {
          error = error.error;
      }
  
      await $shell.FootPrintManager.openErrorDialog(`Error during ${section}`, error.message);
  }
  
  
  $editor.toolbar.process.control.styles.resetStyle();
  $editor.toolbar.process.control.icon = "icon ms-Icon ms-Icon--Process";
  $editor.toolbar.process.control.label = 'Process';
  
  $editor.toolbar.process.control.readOnly = false;
  $editor.toolbar.cancel.control.readOnly = false;
  $editor.toolbar.on_delete.control.readOnly = false;
  
  $editor.refresh();
  }
  on_save(event = null) {
    return this.on_saveInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_saveInternal(
    $editor: FootPrintManager_sales_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const allRequiredFieldHaveValues = $utils.isAllDefined(
      $editor.fields.lookupcode.control.value,
      $editor.fields.order_class.control.value,
      $editor.fields.project.control.value
  );
  
  if (allRequiredFieldHaveValues === false) {
      return;
  }
  
  var shipmentId = $editor.vars.shipmentId;
  
  
  let payload: any = {};
  if ($editor.fields.project.control.isChanged) {
      payload.ProjectId = $editor.fields.project.control.value;
  }
  if ($editor.fields.lookupcode.control.isChanged) {
      payload.LookupCode = $editor.fields.lookupcode.control.value;
  }
  if ($editor.fields.order_class.control.isChanged) {
      payload.OrderClassId = $editor.fields.order_class.control.value;
  }
  if ($editor.fields.reference.control.isChanged) {
      payload.OwnerReference = $editor.fields.reference.control.value;
  }
  if ($editor.fields.vendor_reference.control.isChanged) {
      payload.VendorReference = $editor.fields.vendor_reference.control.value;
  }
  if ($editor.fields.carrier.control.isChanged) {
      payload.PreferredCarrierId = $editor.fields.carrier.control.value;
      // Clear the carrier service type as it could conflict with the newly selected carrier
      payload.PreferredCarrierServiceTypeId = null;
      $editor.fields.carrier_service.control.value = null;
      $editor.fields.carrier_service.control.displayText = null;
  
  }
  if ($editor.fields.carrier_service.control.isChanged) {
      payload.PreferredCarrierServiceTypeId = $editor.fields.carrier_service.control.value;
  }
  if ($editor.fields.warehouse.control.isChanged) {
      if (!$utils.isDefined($editor.fields.warehouse.control.value)) {
          throw new Error('Please select a warehouse.')
      }
      else {
          payload.PreferredWarehouseId = $editor.fields.warehouse.control.value;
      }
  }
  if ($editor.fields.expected_date.control.isChanged) {
      payload.RequestedDeliveryDate = $editor.fields.expected_date.control.value;
  }
  if ($editor.fields.notes.control.isChanged) {
      payload.Notes = $editor.fields.notes.control.value;
  }
  
   
  
  await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.entity.Id, entity: payload });
  
  
  // Create the shipment if it does not exist only if the order has a preferred warehouse of if the preferred warehouse is selected in the editor
  if (!$utils.isDefined(shipmentId)) {
  
      const nextShipmentId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Shipment' })).nextId;
  
      const order = (await $datasources.SalesOrders.ds_get_order_by_orderId.get({ orderId: $editor.inParams.orderId })).result;
  
  
  
      if ($utils.isAllDefined(order, order.PreferredWarehouseId) || $utils.isAllDefined(order, $editor.fields.warehouse.control.value)) {
  
          // create shipment order lookup
          const payload = {
              "OrderId": $editor.inParams.orderId,
              "Shipment": {
                  "Id": nextShipmentId,
                  "LookupCode": order.LookupCode,
                  "CarrierId": order.PreferredCarrierId,
                  "CarrierServiceTypeId": order.PreferredCarrierServiceTypeId,
                  "ExpectedWarehouseId": $utils.isDefined($editor.fields.warehouse.control.value) ? $editor.fields.warehouse.control.value : order.PreferredWarehouseId,
                  "ExpectedDate": order.RequestedDeliveryDate,
                  "Cartonized": false,
                  "ConfirmSeal": false,
                  "StatusId": 1,
                  "Transhipment": false,
                  "TypeId": 2
                  
                  
                 
  
                  
              }
          }
          await $flows.Utilities.crud_create_flow({ entitySet: 'ShipmentOrderLookup', entity: payload });
  
          shipmentId = nextShipmentId;
         $editor.vars.shipmentId = nextShipmentId;
  
      }
  
  }
  
  if ($utils.isDefined(shipmentId)) {
  
      payload = {};
  
      if ($editor.fields.lookupcode.control.isChanged) {
          payload.LookupCode = $editor.fields.lookupcode.control.value;
      }
      if ($editor.fields.carrier.control.isChanged) {
          payload.CarrierId = $editor.fields.carrier.control.value;
          // Clear the carrier service type as it could conflict with the newly selected carrier
          payload.CarrierServiceTypeId = null;
          $editor.fields.carrier_service.control.value = null;
          $editor.fields.carrier_service.control.displayText = null;
      }
      if ($editor.fields.carrier_service.control.isChanged) {
          payload.CarrierServiceTypeId = $editor.fields.carrier_service.control.value;
      }
      if ($editor.fields.warehouse.control.isChanged) {
          if (!$utils.isDefined($editor.fields.warehouse.control.value)) {
              throw new Error('Please select a warehouse.')
          }
          else {
              payload.ExpectedWarehouseId = $editor.fields.warehouse.control.value;
          }
      }
      if ($editor.fields.expected_date.control.isChanged) {
          payload.ExpectedDate = $editor.fields.expected_date.control.value;
      }
      if ($editor.fields.container_identifier.control.isChanged) {
          payload.ContainerIdentifier = $editor.fields.container_identifier.control.value;
      }
  
      if ($editor.fields.notes.control.isChanged) {
          payload.Notes = $editor.fields.notes.control.value;
      }
      if ($editor.fields.bill_of_lading.control.isChanged) {
          payload.BillOfLading = $editor.fields.bill_of_lading.control.value;
      }
      if ($editor.fields.seal.control.isChanged) {
          payload.SealId = $editor.fields.seal.control.value;
      }
      if ($editor.fields.trailer.control.isChanged) {
          payload.TrailerId = $editor.fields.trailer.control.value;
      }
      if ($editor.fields.reference.control.isChanged) {
          payload.ReferenceNumber = $editor.fields.reference.control.value;
      }
      if ($editor.fields.tracking.control.isChanged) {
          payload.TrackingIdentifier = $editor.fields.tracking.control.value;
      }
  
      if ($editor.fields.pickup_date.control.isChanged) {
         payload.PickupDate = $editor.fields.pickup_date.control.value;
  	}
  
      await $flows.Utilities.crud_update_flow({ entitySet: 'Shipments', id: $editor.vars.shipmentId, entity: payload });
  
  }
  
  await $editor.refresh();
  }
  on_ship_to_clicked(event = null) {
    return this.on_ship_to_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_ship_to_clickedInternal(
    $editor: FootPrintManager_sales_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 03/29/2024
  
  const dialogResult = await $shell.FootPrintManager.openaccounts_gridDialog({
      ownerId: $editor.entity.Project.OwnerId,
      typeIds: [2, 4, 5], // Shipping, Residential, Commerical
      accountTypeId: 2, // Customer
      allowSelection: true
  });
  
  const userConfirmed = dialogResult.confirm;
  const accountId = dialogResult.accountId;
  const addressId = dialogResult.addressId;
  const contactId = dialogResult.contactId;
  const ownerId = dialogResult.ownerId;
  
  if (userConfirmed && $utils.isAllDefined(accountId, addressId)) {
      let payload: any = {};
  
      payload.AccountId = accountId;
      payload.BillingAddresId = addressId;
      await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.entity.Id, entity: payload });
  
      if (ownerId === 0) {
  
          const owner_account = (await $datasources.Addresses.ds_find_owner_account_lookup.get({ ownerId: $editor.entity.Project.OwnerId, accountId: accountId })).result;
  
          if (!$utils.isDefined(owner_account)) {
              // OwnersAccounts
              const payload = {
                  "OwnerId": $editor.entity.Project.OwnerId,
                  "AccountId": accountId,
                  "IsDefaultAccount": false
              }
              await $flows.Utilities.crud_create_flow({ entitySet: 'OwnersAccountsLookup', entity: payload });
          }
      }
  
      if ($utils.isDefined($editor.entity.ShipmentOrderLookups)) {
          const shipmentId = $editor.entity.ShipmentOrderLookups[0].ShipmentId;
          let shipment_payload: any = {};
  
          shipment_payload.AccountId = accountId;
          shipment_payload.ContactId = contactId;
  
          await $flows.Utilities.crud_update_flow({ entitySet: 'Shipments', id: shipmentId, entity: shipment_payload });
      }
  }
  // Always refresh as the user might have made some changes to an existing address
  await $editor.refresh();
  }
  on_auto_pick_clicked(event = null) {
    return this.on_auto_pick_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_auto_pick_clickedInternal(
    $editor: FootPrintManager_sales_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Check if order is pickable
  const result = await $flows.SalesOrders.is_sales_order_pickable_flow({
      orderId: $editor.entity.Id,
      shipmentId: $editor.entity.ShipmentOrderLookups[0].ShipmentId
  });
  
  const reason = result.reason;
  if ($utils.isDefined(reason)) {
      $shell.FootPrintManager.openToaster('Order cannot be picked.', reason, EToasterType.Error, { timeOut: 10000, tapToDismiss: false, positionClass: EToasterPosition.topRight });
      return;
  }
  
  // Get warehouseId
  var warehouseId = $editor.entity.PreferredWarehouseId
  if (!$utils.isDefined(warehouseId)) {
  
      openErrorToaster('Order Auto Pick Error', 'Unable to determine preferred warehouse from first order selected');
      return;
  }
  
  
  // Get assigned or scheduled dock door from dock appointment if it exists
  const dockappointment = (await $datasources.DockAppointments.ds_get_dock_appointment_by_orderId.get({ orderId: $editor.entity.Id })).result;
  if ($utils.isDefined(dockappointment)) {
  
      const doorId = dockappointment[0]?.DockAppointment?.AssignedLocation?.Id ?? dockappointment[0]?.DockAppointment?.ScheduledLocation?.Id;
  
      if ($utils.isDefined(doorId)) {
          const dockDoor = (await $datasources.DockAppointments.ds_get_dock_door_by_locationId.get({ locationId: doorId })).result;
          if ($utils.isDefined(dockDoor)) {
              // Get child location of type inventory
              var locationId = dockDoor.ChildLocationContainers[0]?.Id
          }
  
      }
  }
  
  // Get user input for auto-picking
  const dialogResult = await $shell.FootPrintManager.openauto_pick_options_formDialog({
      warehouse_id: warehouseId,
      load_location_id: locationId
  });
  
  var userConfirmed = dialogResult.confirm;
  var autoLoadConfirmed = dialogResult.auto_load;
  var loadLocation = dialogResult.load_location;
  let license_plate_lookup = dialogResult.license_plate_lookup;
  
  if (!userConfirmed) { return; }
  
  try {
      const result = await $flows.SalesOrders.auto_pick_sales_order_flow({
          orderId: $editor.entity.Id,
          shipmentId: $editor.entity.ShipmentOrderLookups[0].ShipmentId,
          license_plate_lookup: license_plate_lookup
      });
      const reason = result.reason;
  
      if ($utils.isAllDefined(userConfirmed, autoLoadConfirmed) && autoLoadConfirmed === true) {
  
          const flowLoadParams = {
              orderId: $editor.entity.Id,
              shipmentId: $editor.entity.ShipmentOrderLookups[0].ShipmentId,
              loadLocation: $utils.isDefined(loadLocation) ? loadLocation : null
          }
          const resultLoad = await $flows.SalesOrders.auto_load_sales_order_flow(flowLoadParams);
          var reasonLoad = resultLoad.reason;
      }
  
      if ($utils.isDefined(reason)) {
          openErrorToaster('Order cannot be fully auto picked.', reason);
          await $editor.refresh();
          await $editor.tabs.picking_tasks.activate();
          return;
      }
      else if ($utils.isDefined(reasonLoad)) {
          openErrorToaster('Order cannot be fully auto loaded.', reasonLoad);
          await $editor.refresh();
          await $editor.tabs.shipping_contents.activate();
          return;
      }
      else {
  
          const loadmessage = (autoLoadConfirmed === true) ? ' and loaded' : ''
          const title = `Order ${$editor.entity.LookupCode} picked${loadmessage}`;
          $shell.FootPrintManager.openToaster(title, '', EToasterType.Success, { timeOut: 5000, positionClass: EToasterPosition.topRight });
          await $editor.refresh();
          await $editor.tabs.shipping_contents.activate();
      }
  
  } catch (error) {
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
      const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
      await $shell.FootPrintManager.openErrorDialog('Auto pick order error', 'An error occurred during the auto picking of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
      await $editor.refresh();
      await $editor.tabs.picking_tasks.activate();
  }
  
  
  
  
  /************************************
   * FUNCTIONS
  *************************************/
  function openErrorToaster(title: string, message?: string) {
      $shell.FootPrintManager.openToaster(title, message, EToasterType.Error, { timeOut: 10000, tapToDismiss: false, positionClass: EToasterPosition.topRight });
  }
  }
  on_auto_load_clicked(event = null) {
    return this.on_auto_load_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_auto_load_clickedInternal(
    $editor: FootPrintManager_sales_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const result = await $flows.SalesOrders.is_sales_order_loadable_flow({
      orderId: $editor.entity.Id,
      shipmentId: $editor.entity.ShipmentOrderLookups[0].ShipmentId
  });
  
  const reason = result.reason;
  if ($utils.isDefined(reason)) {
      await $shell.FootPrintManager.openErrorDialog('Order cannot be loaded.', reason);
      return;
  } else {
  
      var warehouseId = $editor.entity.PreferredWarehouseId
      if (!$utils.isDefined(warehouseId)) {
  
          $shell.FootPrintManager.openErrorDialog('Order Auto Load Error', 'Unable to determine preferred warehouse from first order selected');
          return;
      }
      // Get assigned or scheduled dock door from dock appointment if it exists
      const dockappointment = (await $datasources.DockAppointments.ds_get_dock_appointment_by_orderId.get({ orderId: $editor.entity.Id })).result;
  
      if ($utils.isDefined(dockappointment)) {
  
          const doorId = dockappointment[0]?.DockAppointment?.AssignedLocation?.Id ?? dockappointment[0]?.DockAppointment?.ScheduledLocation?.Id;
  
          if ($utils.isDefined(doorId)) {
              const dockDoor = (await $datasources.DockAppointments.ds_get_dock_door_by_locationId.get({ locationId: doorId })).result;
              if ($utils.isDefined(dockDoor)) {
                  // Get child location of type inventory
                  var locationId = dockDoor.ChildLocationContainers[0]?.Id
              }
  
          }
      }
  
      const dialogResult = await $shell.FootPrintManager.openauto_load_options_formDialog({
          warehouseId: warehouseId,
          loadLocationId: locationId
      });
  
      var userConfirmed = dialogResult.confirm;
      var loadLocation = dialogResult.loadLocation;
  
      if ($utils.isDefined(userConfirmed) && userConfirmed === false) {
  
          return;
      }
      if (userConfirmed) {
          try {
  
              const result = await $flows.SalesOrders.auto_load_sales_order_flow({
                  orderId: $editor.entity.Id,
                  shipmentId: $editor.entity.ShipmentOrderLookups[0].ShipmentId,
                  loadLocation: loadLocation
              });
              const reason = result.reason;
  
              if ($utils.isDefined(reason)) {
                  await $shell.FootPrintManager.openErrorDialog('Order cannot be fully auto loaded.', reason);
                  return;
              }
              else {
                  const title = `Order ${$editor.entity.LookupCode} loaded`;
                  $shell.FootPrintManager.openToaster(title, '', EToasterType.Success, { timeOut: 5000, positionClass: EToasterPosition.topRight });
                  await $editor.refresh();
                  await $editor.tabs.shipping_contents.activate();
              }
  
          } catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.FootPrintManager.openErrorDialog('Auto load order error', 'An error occurred during the auto loading of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
  
      }
  
  }
  }
  on_revert_clicked(event = null) {
    return this.on_revert_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_revert_clickedInternal(
    $editor: FootPrintManager_sales_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const result = await $flows.SalesOrders.is_sales_order_revertable_flow({
      orderId: $editor.entity.Id,
      shipmentId: $editor.vars.shipmentId
  });
  const reason = result.reason;
  if ($utils.isDefined(reason)) {
      await $shell.FootPrintManager.openErrorDialog('Order cannot be reverted.', reason);
      return;
  } else {
      const confirm = await $shell.FootPrintManager.openConfirmationDialog('Revert Order', `Are you sure you want to bring the order back to the original created status  ${$editor.entity.LookupCode}?`, 'Yes', 'No');
      if (confirm) {
          try {
              // Lock down buttons
              $editor.toolbar.revert.control.readOnly = true;
  
              const result = await $flows.SalesOrders.revert_sales_order_flow({
                  orderId: $editor.entity.Id,
                  shipmentId: $editor.vars.shipmentId
              });
              const reason = result.reason;
  
              if ($utils.isDefined(reason)) {
                  await $shell.FootPrintManager.openErrorDialog('Order cannot be reverted.', reason);
                  $editor.toolbar.revert.control.readOnly = false;
                  return;
              }
              else {
                  const title = `Order ${$editor.entity.LookupCode} reverted`
                  $shell.FootPrintManager.openToaster(title, '', EToasterType.Success, { timeOut: 5000, positionClass: EToasterPosition.topRight });
                  
                  $editor.refresh();
                  await $editor.tabs.sales_order_lines.activate();
              }
          } catch (error) {
              $editor.toolbar.revert.control.readOnly = false;
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.FootPrintManager.openErrorDialog('Order revert error', 'An error occurred while reverting the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  }
  on_appointment_clicked(event = null) {
    return this.on_appointment_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_appointment_clickedInternal(
    $editor: FootPrintManager_sales_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const dockAppointments = (await $datasources.DockAppointments.ds_get_dock_appointment_by_orderId.get({
      orderId: $editor.inParams.orderId
  })).result;
  
  if ($utils.isDefined(dockAppointments)) {
  
      const dialogResult = await $shell.FootPrintManager.opendock_appointment_editorDialog({
          dockAppointmentId: dockAppointments[0].DockAppointmentId
      })
  
  } else {
        // Check if the selected order is in a rejected status and throw an error.
      if ($editor.entity.OrderStatusId == 4096) {
  
          throw new Error('Selected order is currently in a rejected status, a dock appointment cannot be created against rejected orders.')
      }
  
      const nextAppointmentId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'DockAppointment' })).nextId;
  
      const dialogResult = await $shell.FootPrintManager.opendock_appointment_creation_formDialog({
         
          warehouseId: [$editor.entity.PreferredWarehouseId],
          lookupcode: nextAppointmentId.toString(),
          scheduledArrival: $utils.isDefined($editor.entity.RequestedDeliveryDate) ? $editor.entity.RequestedDeliveryDate : $utils.date.now(),
          scheduledDeparture: $utils.isDefined($editor.entity.RequestedDeliveryDate) ? $utils.date.add(1, 'hour', $editor.entity.RequestedDeliveryDate) : $utils.date.add(1, 'hour', $utils.date.now()),
          typeId: 2,
          ownerId:  $editor.entity.Project.OwnerId,
          projectId: $editor.entity.ProjectId,
          carrierId: $editor.entity.PreferredCarrierId,
          orderId: $editor.inParams.orderId
      });
  
  }
  
  // Always refresh as the user might have made some changes to an existing address
  await $editor.refresh();
  
  }
  on_print_clicked(event = null) {
    return this.on_print_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_print_clickedInternal(
    $editor: FootPrintManager_sales_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  
  
  
  
  
  
  await $shell.SalesOrders.openload_slip_reportDialog({ orderId: $editor.inParams.orderId });
  }
  on_ship_to_cleared(event = null) {
    return this.on_ship_to_clearedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_ship_to_clearedInternal(
    $editor: FootPrintManager_sales_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  const orderAddress = (await $flows.Addresses.create_generic_order_address_flow({ orderId: $editor.inParams.orderId }));
  if ($utils.isDefined(orderAddress)) {
  
      var accountId = orderAddress.accountId;
      var addressId = orderAddress.addressId;
  
  }
  
  if ($utils.isAllDefined(accountId, addressId)) {
  
  
      let payload: any = {};
  
      payload.AccountId = accountId;
      payload.BillingAddresId = addressId;
  
      await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.entity.Id, entity: payload });
  
  
  
      await $editor.refresh();
  
  
  }
  else {
  
      throw new Error('Unable to create clear selected ship to account.')
  }
  }
  apply_operations(event = null) {
    return this.apply_operationsInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async apply_operationsInternal(
    $editor: FootPrintManager_sales_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //Get the temperature capture operation
  
  if (await $operations.FootPrintManager.Disable_Temperatures_Entry.isAuthorized()) {
      $editor.toolbar.options.control.buttons.temperature_readings.readOnly = true;
  };
  
  if (await $operations.FootPrintManager.Disable_SalesOrders_AutoPicking.isAuthorized()) {
      $editor.toolbar.auto_pick.hidden = true;
  }
  }
  on_attachments_clicked(event = null) {
    return this.on_attachments_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_attachments_clickedInternal(
    $editor: FootPrintManager_sales_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //await $shell.FootPrintManager.openentity_attachments_gridDialog({ entityType: 'Order', entityKeys: [{ name: 'Id', value: $editor.entity.Id }]});
  await $shell.FootPrintManager.openorders_attachments_hubDialog({orderId: $editor.entity.Id, shipmentId: $editor.entity.ShipmentOrderLookups[0].ShipmentId})
  }
  on_surveys_clicked(event = null) {
    return this.on_surveys_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_surveys_clickedInternal(
    $editor: FootPrintManager_sales_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  if ($utils.isDefined($editor.entity.ShipmentOrderLookups)) {
      await $shell.FootPrintManager.opensubmitted_surveys_gridDialog({
          entities: [{ type: 'Shipment', ids: [$editor.entity.ShipmentOrderLookups[0].ShipmentId] }],
          operationContextTypes: null,
          warehouseIds: null
      }, 'modal');
  }
  
  
  }
  on_driver_check_out_clicked(event = null) {
    return this.on_driver_check_out_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_driver_check_out_clickedInternal(
    $editor: FootPrintManager_sales_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  // Open driver check out hub
  $shell.FootPrintManager.opendriver_check_out_hub({
      orderId: $editor.inParams.orderId});
  }
  on_temperature_readings(event = null) {
    return this.on_temperature_readingsInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_temperature_readingsInternal(
    $editor: FootPrintManager_sales_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  $shell.FootPrintManager.opentemperature_readings_gridDialog({
      orderId: $editor.inParams.orderId
  });
  }
  on_custom_fields_clicked(event = null) {
    return this.on_custom_fields_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_custom_fields_clickedInternal(
    $editor: FootPrintManager_sales_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  await $shell.FootPrintManager.openentity_user_defined_field_values_gridDialog({ entityType: 'Order', entityKeys: [{name: 'Id', value: $editor.inParams.orderId}]}, 'modal');
  }
  on_discussions_clicked(event = null) {
    return this.on_discussions_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_discussions_clickedInternal(
    $editor: FootPrintManager_sales_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  await $shell.Discussions.opendiscussions_listDialog({
      entity_name: 'Order',
      entity_id: $editor.inParams.orderId.toString()
  },
  'flyout')
  
  $editor.refresh();
  }
  on_email_requests_clicked(event = null) {
    return this.on_email_requests_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_email_requests_clickedInternal(
    $editor: FootPrintManager_sales_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  await $shell.Notifications.openemail_requests_gridDialog({
      entityType: 'Order',
      entityId: $editor.entity.Id
  }, 'modal');
  }
  on_integration_clicked(event = null) {
    return this.on_integration_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_integration_clickedInternal(
    $editor: FootPrintManager_sales_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 05/29/2024
  
  await $shell.FootPrintManager.openintegration_info_by_order_idDialog({ order_id: $editor.inParams.orderId }, "flyout");
  }
  on_pallet_transactions_clicked(event = null) {
    return this.on_pallet_transactions_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_pallet_transactions_clickedInternal(
    $editor: FootPrintManager_sales_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/12/2024
  
  if ($utils.isDefined($editor.vars.shipmentId)) {
  
      await $shell.FootPrintManager.openpallet_transactions_shipment_gridDialog({
          project_id: $editor.entity.ProjectId,
          shipment_id: $editor.vars.shipmentId,
          order_lookup_code: $editor.entity.LookupCode
      }, "flyout", EModalSize.Large)
  
  } else {
  
      await $shell.FootPrintManager.openInfoDialog("Missing shipment!", "A shipment Id could not be found for this order!")
  }
  }
  on_so_print_labels(event = null) {
    return this.on_so_print_labelsInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_so_print_labelsInternal(
    $editor: FootPrintManager_sales_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $shell.FootPrintManager.openprint_order_labels_formDialog({ orderId: $editor.entity.Id ,context:"so"});
  }
  on_copy_order_clicked(event = null) {
    return this.on_copy_order_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_copy_order_clickedInternal(
    $editor: FootPrintManager_sales_order_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  
  
  
  await $shell.FootPrintManager.opensales_order_copy_formDialog({
      order_id: $editor.inParams.orderId
  },'modal');
  
  
  }
  //#endregion private flows
}
