import { Injectable } from '@angular/core';
import { MissingTranslationHandler, TranslateCompiler, TranslateParser, TranslateService, TranslateStore } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Language, LocalizationService, LocalizationSettings } from './localization.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BarcodeParsing_LocalizationService } from './BarcodeParsing.localization.service';
import { BarTender_LocalizationService } from './BarTender.localization.service';
import { Utilities_LocalizationService } from './Utilities.localization.service';
import { Addresses_LocalizationService } from './Addresses.localization.service';
import { Attachments_LocalizationService } from './Attachments.localization.service';
import { Carriers_LocalizationService } from './Carriers.localization.service';
import { Cartonization_LocalizationService } from './Cartonization.localization.service';
import { EntityImport_LocalizationService } from './EntityImport.localization.service';
import { Equipment_LocalizationService } from './Equipment.localization.service';
import { ExcelMaterialImport_LocalizationService } from './ExcelMaterialImport.localization.service';
import { ExcelProjectImport_LocalizationService } from './ExcelProjectImport.localization.service';
import { Inspections_LocalizationService } from './Inspections.localization.service';
import { Instructions_LocalizationService } from './Instructions.localization.service';
import { InventoryTransfers_LocalizationService } from './InventoryTransfers.localization.service';
import { LaborManagement_LocalizationService } from './LaborManagement.localization.service';
import { LoadContainers_LocalizationService } from './LoadContainers.localization.service';
import { Locations_LocalizationService } from './Locations.localization.service';
import { PrintNode_LocalizationService } from './PrintNode.localization.service';
import { Materials_LocalizationService } from './Materials.localization.service';
import { PackVerification_LocalizationService } from './PackVerification.localization.service';
import { PalletTransactions_LocalizationService } from './PalletTransactions.localization.service';
import { PurchaseOrders_LocalizationService } from './PurchaseOrders.localization.service';
import { Replenishments_LocalizationService } from './Replenishments.localization.service';
import { Returns_LocalizationService } from './Returns.localization.service';
import { ShippingContainers_LocalizationService } from './ShippingContainers.localization.service';
import { Surveys_LocalizationService } from './Surveys.localization.service';
import { TemperatureReadings_LocalizationService } from './TemperatureReadings.localization.service';
import { TransloadOrders_LocalizationService } from './TransloadOrders.localization.service';
import { Waves_LocalizationService } from './Waves.localization.service';
import { WorkOrders_LocalizationService } from './WorkOrders.localization.service';
import { Lots_LocalizationService } from './Lots.localization.service';
import { Invoices_LocalizationService } from './Invoices.localization.service';
import { DockAppointments_LocalizationService } from './DockAppointments.localization.service';
import { Inventory_LocalizationService } from './Inventory.localization.service';
import { ExcelLocationImport_LocalizationService } from './ExcelLocationImport.localization.service';
import { SerialNumbers_LocalizationService } from './SerialNumbers.localization.service';
import { InventoryCounts_LocalizationService } from './InventoryCounts.localization.service';
import { AsnOrders_LocalizationService } from './AsnOrders.localization.service';
import { Manifesting_LocalizationService } from './Manifesting.localization.service';
import { Owners_LocalizationService } from './Owners.localization.service';
import { Discussions_LocalizationService } from './Discussions.localization.service';
import { Notifications_LocalizationService } from './Notifications.localization.service';
import { WarehouseTransfers_LocalizationService } from './WarehouseTransfers.localization.service';
import { ExcelInventoryImport_LocalizationService } from './ExcelInventoryImport.localization.service';
import { SalesOrders_LocalizationService } from './SalesOrders.localization.service';
import { ExcelOrderImport_LocalizationService } from './ExcelOrderImport.localization.service';
import { Document360_LocalizationService } from './Document360.localization.service';
import { Usersnap_LocalizationService } from './Usersnap.localization.service';

@Injectable({
  providedIn: 'root'
})
export class FootPrintManager_LocalizationService
  extends LocalizationService<{  }> {

  public FootPrintManager: FootPrintManager_LocalizationService = this;

  public override get settings(): LocalizationSettings {
    return {"default":"","languages":[]}
  }
  
  private _referencedServices: LocalizationService<any>[] = [];

  constructor(
    httpClient: HttpClient,
    compiler: TranslateCompiler,
    parser: TranslateParser,
    missingHandler: MissingTranslationHandler,
    public BarcodeParsing: BarcodeParsing_LocalizationService,
    public BarTender: BarTender_LocalizationService,
    public Utilities: Utilities_LocalizationService,
    public Addresses: Addresses_LocalizationService,
    public Attachments: Attachments_LocalizationService,
    public Carriers: Carriers_LocalizationService,
    public Cartonization: Cartonization_LocalizationService,
    public EntityImport: EntityImport_LocalizationService,
    public Equipment: Equipment_LocalizationService,
    public ExcelMaterialImport: ExcelMaterialImport_LocalizationService,
    public ExcelProjectImport: ExcelProjectImport_LocalizationService,
    public Inspections: Inspections_LocalizationService,
    public Instructions: Instructions_LocalizationService,
    public InventoryTransfers: InventoryTransfers_LocalizationService,
    public LaborManagement: LaborManagement_LocalizationService,
    public LoadContainers: LoadContainers_LocalizationService,
    public Locations: Locations_LocalizationService,
    public PrintNode: PrintNode_LocalizationService,
    public Materials: Materials_LocalizationService,
    public PackVerification: PackVerification_LocalizationService,
    public PalletTransactions: PalletTransactions_LocalizationService,
    public PurchaseOrders: PurchaseOrders_LocalizationService,
    public Replenishments: Replenishments_LocalizationService,
    public Returns: Returns_LocalizationService,
    public ShippingContainers: ShippingContainers_LocalizationService,
    public Surveys: Surveys_LocalizationService,
    public TemperatureReadings: TemperatureReadings_LocalizationService,
    public TransloadOrders: TransloadOrders_LocalizationService,
    public Waves: Waves_LocalizationService,
    public WorkOrders: WorkOrders_LocalizationService,
    public Lots: Lots_LocalizationService,
    public Invoices: Invoices_LocalizationService,
    public DockAppointments: DockAppointments_LocalizationService,
    public Inventory: Inventory_LocalizationService,
    public ExcelLocationImport: ExcelLocationImport_LocalizationService,
    public SerialNumbers: SerialNumbers_LocalizationService,
    public InventoryCounts: InventoryCounts_LocalizationService,
    public AsnOrders: AsnOrders_LocalizationService,
    public Manifesting: Manifesting_LocalizationService,
    public Owners: Owners_LocalizationService,
    public Discussions: Discussions_LocalizationService,
    public Notifications: Notifications_LocalizationService,
    public WarehouseTransfers: WarehouseTransfers_LocalizationService,
    public ExcelInventoryImport: ExcelInventoryImport_LocalizationService,
    public SalesOrders: SalesOrders_LocalizationService,
    public ExcelOrderImport: ExcelOrderImport_LocalizationService,
    public Document360: Document360_LocalizationService,
    public Usersnap: Usersnap_LocalizationService
    ) {
    super(
      new TranslateService(
        new TranslateStore(),
        new TranslateHttpLoader(httpClient, '../assets/i18n/FootPrintManager/', '.json'),
        compiler,
        parser,
        missingHandler,
        true,
        true,
        false,
        ''));

    this._referencedServices.push(this.BarcodeParsing);

    this._referencedServices.push(this.BarTender);

    this._referencedServices.push(this.Utilities);

    this._referencedServices.push(this.Addresses);

    this._referencedServices.push(this.Attachments);

    this._referencedServices.push(this.Carriers);

    this._referencedServices.push(this.Cartonization);

    this._referencedServices.push(this.EntityImport);

    this._referencedServices.push(this.Equipment);

    this._referencedServices.push(this.ExcelMaterialImport);

    this._referencedServices.push(this.ExcelProjectImport);

    this._referencedServices.push(this.Inspections);

    this._referencedServices.push(this.Instructions);

    this._referencedServices.push(this.InventoryTransfers);

    this._referencedServices.push(this.LaborManagement);

    this._referencedServices.push(this.LoadContainers);

    this._referencedServices.push(this.Locations);

    this._referencedServices.push(this.PrintNode);

    this._referencedServices.push(this.Materials);

    this._referencedServices.push(this.PackVerification);

    this._referencedServices.push(this.PalletTransactions);

    this._referencedServices.push(this.PurchaseOrders);

    this._referencedServices.push(this.Replenishments);

    this._referencedServices.push(this.Returns);

    this._referencedServices.push(this.ShippingContainers);

    this._referencedServices.push(this.Surveys);

    this._referencedServices.push(this.TemperatureReadings);

    this._referencedServices.push(this.TransloadOrders);

    this._referencedServices.push(this.Waves);

    this._referencedServices.push(this.WorkOrders);

    this._referencedServices.push(this.Lots);

    this._referencedServices.push(this.Invoices);

    this._referencedServices.push(this.DockAppointments);

    this._referencedServices.push(this.Inventory);

    this._referencedServices.push(this.ExcelLocationImport);

    this._referencedServices.push(this.SerialNumbers);

    this._referencedServices.push(this.InventoryCounts);

    this._referencedServices.push(this.AsnOrders);

    this._referencedServices.push(this.Manifesting);

    this._referencedServices.push(this.Owners);

    this._referencedServices.push(this.Discussions);

    this._referencedServices.push(this.Notifications);

    this._referencedServices.push(this.WarehouseTransfers);

    this._referencedServices.push(this.ExcelInventoryImport);

    this._referencedServices.push(this.SalesOrders);

    this._referencedServices.push(this.ExcelOrderImport);

    this._referencedServices.push(this.Document360);

    this._referencedServices.push(this.Usersnap);
    
    this.initialize();

  }

  public override setLanguage(lang: Language): void{
    super.setLanguage(lang);
  
    for (let i of this._referencedServices){
      i.setLanguage(lang);
    }
  }
}