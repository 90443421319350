import { Inject, Injectable, Injector } from '@angular/core';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';

import { Utilities_OperationService } from './Utilities.operation.service';
import { Attachments_OperationService } from './Attachments.operation.service';
import { Instructions_OperationService } from './Instructions.operation.service';
import { Materials_OperationService } from './Materials.operation.service';
import { PurchaseOrders_OperationService } from './PurchaseOrders.operation.service';
import { Invoices_OperationService } from './Invoices.operation.service';
import { Inventory_OperationService } from './Inventory.operation.service';
import { Owners_OperationService } from './Owners.operation.service';
import { Notifications_OperationService } from './Notifications.operation.service';
import { SalesOrders_OperationService } from './SalesOrders.operation.service';


@Injectable({ providedIn: 'root' })
export class FootPrintApiManager_OperationService {

  constructor(
    private injector: Injector,
    private utils: UtilsService,
  ) {
    this.Utilities = this.injector.get(Utilities_OperationService);
    this.Attachments = this.injector.get(Attachments_OperationService);
    this.Instructions = this.injector.get(Instructions_OperationService);
    this.Materials = this.injector.get(Materials_OperationService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_OperationService);
    this.Invoices = this.injector.get(Invoices_OperationService);
    this.Inventory = this.injector.get(Inventory_OperationService);
    this.Owners = this.injector.get(Owners_OperationService);
    this.Notifications = this.injector.get(Notifications_OperationService);
    this.SalesOrders = this.injector.get(SalesOrders_OperationService);
  }

    public Utilities: Utilities_OperationService;
    public Attachments: Attachments_OperationService;
    public Instructions: Instructions_OperationService;
    public Materials: Materials_OperationService;
    public PurchaseOrders: PurchaseOrders_OperationService;
    public Invoices: Invoices_OperationService;
    public Inventory: Inventory_OperationService;
    public Owners: Owners_OperationService;
    public Notifications: Notifications_OperationService;
    public SalesOrders: SalesOrders_OperationService;
  public FootPrintApiManager: FootPrintApiManager_OperationService = this;

  public Enable_ApiManager_AlertsGrid = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Enable_ApiManager_AlertsGrid');
    }
  }
  public Enable_ApiManager_ConfigurationsGrid = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Enable_ApiManager_ConfigurationsGrid');
    }
  }
  public Enable_ApiManager_IntegrationsGrid = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Enable_ApiManager_IntegrationsGrid');
    }
  }
  public Enable_ApiManager_LifecycleGrid = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Enable_ApiManager_LifecycleGrid');
    }
  }
  public Enable_ApiManager_ManualRunner = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Enable_ApiManager_ManualRunner');
    }
  }
  public Enable_ApiManager_MessagesGrid = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Enable_ApiManager_MessagesGrid');
    }
  }
  public Enable_ApiManager_ProcessesGrid = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Enable_ApiManager_ProcessesGrid');
    }
  }
  public Enable_ApiManager_StatusesGrid = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Enable_ApiManager_StatusesGrid');
    }
  }
  public Enable_ApiManager_TransactionTypesGrid = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Enable_ApiManager_TransactionTypesGrid');
    }
  }

  private isAuthorized(operationName: string): Promise<boolean> {
    return this.utils.http.post(`${environment.backendUrl}api/FootPrintApiManager/operations/${operationName}/isauthorized`, null);
  }
}
